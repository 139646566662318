import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { FormText } from 'reactstrap';
import CustomerSelect from '../select/CustomerSelect';
import { optionsPropTypes } from '../select/SelectPropTypes';

const CustomerField = ({
  fieldName,
  onChange,
  noOptionsMessage,
  isDisabled,
  validate,
  placeholder,
  isClearable,
  isMulti,
  options,
  autoFocus,
  searchByDocumentNumber,
}) => (
  <>
    <Field
      name={fieldName}
      component={CustomerSelect}
      onChange={onChange}
      noOptionsMessage={noOptionsMessage}
      isDisabled={isDisabled}
      validate={validate}
      placeholder={placeholder}
      isClearable={isClearable}
      isMulti={isMulti}
      options={options}
      autoFocus={autoFocus}
      searchByDocumentNumber={searchByDocumentNumber}
    />
    <FormText>Ingresa mínimo 4 caracteres</FormText>
  </>
);

CustomerField.propTypes = {
  fieldName: PropTypes.string,
  onChange: PropTypes.func,
  noOptionsMessage: PropTypes.func,
  isDisabled: PropTypes.bool,
  placeholder: PropTypes.string,
  validate: PropTypes.arrayOf(PropTypes.func),
  isClearable: PropTypes.bool,
  isMulti: PropTypes.bool,
  options: optionsPropTypes,
  autoFocus: PropTypes.bool,
  searchByDocumentNumber: PropTypes.bool,
};

CustomerField.defaultProps = {
  fieldName: '',
  onChange: undefined,
  noOptionsMessage: undefined,
  isDisabled: false,
  placeholder: 'Ingrese Nombre o DNI',
  validate: undefined,
  isClearable: false,
  isMulti: false,
  options: [],
  autoFocus: false,
  searchByDocumentNumber: false,
};

export default CustomerField;
