import React from 'react';
import PropTypes from 'prop-types';
import { DATE_TIME_FORMAT, TIME_FORMAT } from '../../../../config/locale';
import { tzNormalizeDate } from '../../../../utils/date';
import { Table } from '../../../common/Table';
import { TIMETABLE_TEMPLATE_COLUMNS } from '../../../../config/columns';

export const TimetableTemplate = ({
  itineraryScheduleSegmentList,
  estimatedDepartureTime,
  showFullDate,
  isSegmentLevel,
}) => {
  const generateTimetableData = () => {
    const timetableData = [];

    const format = showFullDate ? DATE_TIME_FORMAT : TIME_FORMAT;

    itineraryScheduleSegmentList.forEach((row, index, array) => {
      const { segmentAdjustment: durationAdjustment, startOffset } = row;

      const duration = isSegmentLevel ? row.segment.duration : row.duration;

      const sourceLocationName = isSegmentLevel
        ? row.segment.sourceLocation.name
        : row.sourceLocationName;
      const destinationLocationName = isSegmentLevel
        ? row.segment.destinationLocation.name
        : row.destinationLocationName;

      const timetable = {
        sourceLocationName,
        destinationLocationName,
        departureTime: '-',
        duration,
        durationAdjustment,
        arrivalTime: '-',
        layover: 0,
      };

      if (estimatedDepartureTime) {
        timetable.departureTime = tzNormalizeDate({
          date: estimatedDepartureTime,
          addTime: { amount: startOffset, unit: 'minutes' },
          format,
        });

        timetable.arrivalTime = tzNormalizeDate({
          date: estimatedDepartureTime,
          addTime: {
            amount: startOffset + duration + durationAdjustment,
            unit: 'minutes',
          },
          format,
        });

        if (array[index + 1]) {
          const { startOffset: nextStartOffset } = array[index + 1];

          const layover =
            nextStartOffset - durationAdjustment - duration - startOffset;

          timetable.layover = layover;
        }
      }

      timetableData.push(timetable);
    });

    return timetableData;
  };

  const data = generateTimetableData();

  const timetable = (
    <Table
      columns={TIMETABLE_TEMPLATE_COLUMNS}
      data={data}
      defaultPageSize={data.length}
      showPagination={false}
    />
  );

  return timetable;
};

TimetableTemplate.propTypes = {
  itineraryScheduleSegmentList: PropTypes.arrayOf(PropTypes.instanceOf(Object))
    .isRequired,
  estimatedDepartureTime: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.instanceOf(Date),
  ]).isRequired,
  showFullDate: PropTypes.bool,
  isSegmentLevel: PropTypes.bool,
};

TimetableTemplate.defaultProps = {
  showFullDate: true,
  isSegmentLevel: true,
};

export default TimetableTemplate;
