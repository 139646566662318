import React from 'react';
import { Route, Switch } from 'react-router-dom';
import {
  ITINERARY_PATH,
  ITINERARY_FOR_COUNTER_PATH,
  ITINERARY_SCHEDULE_PATH,
  ITINERARY_SEARCH_OMISSION_PATH,
  NEW_ITINERARY_SCHEDULE_PATH,
  NEW_ITINERARY_SEARCH_OMISSION_PATH,
  SERVICE_MERGE_PATH,
  ITINERARY_UNIT_PATH,
  ITINERARY_FOR_SCHEDULING_PATH,
  generateItineraryStatusEditPath,
  NEW_ITINERARY_EXTRAORDINARY_MOVEMENT_PATH,
  generateRescueItineraryPath,
  KIDS_ON_ITINERARY_PATH,
  SALES_CONTROL_PATH,
  BILLINGS_ITINERARY_PATH,
  LIVE_SALES_CONTROL_PATH,
  TRIP_REQUEST_PATH,
  NEW_TRIP_REQUEST_PATH,
  PROCESS_PATH,
  NEW_PROCESS_PATH,
  generateItineraryByProcessStatusEditPath,
} from '../../../config/paths';
import ItineraryHome from './ItineraryHome';
import ItinerarySchedules from './itinerary/itinerary-schedule/ItinerarySchedules';
import NewItinerarySchedule from './itinerary/itinerary-schedule/NewItinerarySchedule';
import ItinerarySchedule from './itinerary/itinerary-schedule/ItinerarySchedule';
import EditItinerarySchedule from './itinerary/itinerary-schedule/EditItinerarySchedule';
import Itineraries from './itinerary/itinerary/Itineraries';
import Itinerary from './itinerary/itinerary/Itinerary';
import ItinerariesForCounter from './itinerary/itinerary-for-counter/ItinerariesForCounter';
import ItineraryForCounter from './itinerary/itinerary-for-counter/ItineraryForCounter';
import EditItineraryForCounter from './itinerary/itinerary-for-counter/EditItineraryForCounter';
import EditItineraryQualityControl from './itinerary/itinerary-for-counter/EditItineraryQualityControl';
import ServiceMerge from './service-merge/ServiceMerge';
import NewItinerarySearchOmission from './itinerary-search-omission/NewItinerarySearchOmission';
import ItinerarySearchOmissions from './itinerary-search-omission/ItinerarySearchOmissions';
import ItinerarySearchOmission from './itinerary-search-omission/ItinerarySearchOmission';
import EditItinerarySearchOmission from './itinerary-search-omission/EditItinerarySearchOmission';
import BaggageItemMovement from './itinerary/itinerary-for-counter/BaggageItemMovement';
import NewTrip from './itinerary/itinerary/NewTrip';
import EditItinerary from './itinerary/itinerary/EditItinerary';
import ItinerariesForScheduling from './itinerary/itinerary-for-scheduling/ItinerariesForScheduling';
import EditItineraryStatus from './itinerary/itinerary-schedule/EditItineraryStatus';
import NewExtraordinaryMovement from './extraordinary-movement/NewExtraordinaryMovement';
import NewRescueItinerary from './extraordinary-movement/NewRescueItinerary';
import KidsOnItinerary from './kids-on-itinerary/KidsOnItinerary';
import ItinerarySalesControl from './sales-control/ItinerarySalesControl';
import BillingsItinerary from './itinerary/billings-for-itinerary/BillingsItinerary';
import LiveItinerarySalesControl from './itinerary/live-itinerary-sales-control/LiveItinerarySalesControl';
import TripRequests from './trip-request/TripRequests';
import NewTripRequest from './trip-request/NewTripRequest';
import TripRequest from './trip-request/TripRequest';
import EditTripRequest from './trip-request/EditTripRequest';
import Processes from './process/Processes';
import Process from './process/Process';
import NewProcess from './process/NewProcess';
import ProcessEditItineraryStatus from './process/ProcessEditItineraryStatus';
import EditProcessSchedule from './process/EditProcessSchedule';
import EditProcess from './process/EditProcess';

const ItineraryManagementModule = () => (
  <Switch>
    <Route exact path={ITINERARY_UNIT_PATH} component={ItineraryHome} />
    <Route
      exact
      path={ITINERARY_SCHEDULE_PATH}
      component={ItinerarySchedules}
    />
    <Route
      exact
      path={NEW_ITINERARY_SCHEDULE_PATH}
      component={NewItinerarySchedule}
    />
    <Route
      exact
      path={`${ITINERARY_SCHEDULE_PATH}/:id`}
      component={ItinerarySchedule}
    />
    <Route
      exact
      path={`${ITINERARY_SCHEDULE_PATH}/:id/edit`}
      component={EditItinerarySchedule}
    />
    <Route
      exact
      path={generateItineraryStatusEditPath()}
      component={EditItineraryStatus}
    />
    <Route exact path={ITINERARY_PATH} component={Itineraries} />
    <Route exact path={`${ITINERARY_PATH}/:id`} component={Itinerary} />
    <Route
      exact
      path={`${ITINERARY_PATH}/:id/edit`}
      component={EditItinerary}
    />
    <Route exact path={`${ITINERARY_PATH}/:id/new-trip`} component={NewTrip} />
    <Route
      exact
      path={ITINERARY_FOR_COUNTER_PATH}
      component={ItinerariesForCounter}
    />
    <Route
      exact
      path={`${ITINERARY_FOR_COUNTER_PATH}/:id`}
      component={ItineraryForCounter}
    />
    <Route
      exact
      path={`${ITINERARY_FOR_COUNTER_PATH}/:id/edit`}
      component={EditItineraryForCounter}
    />
    <Route
      exact
      path={`${ITINERARY_FOR_COUNTER_PATH}/:id/baggage-item-movement`}
      component={BaggageItemMovement}
    />
    <Route
      exact
      path={`${ITINERARY_FOR_COUNTER_PATH}/:id/quality-control`}
      component={EditItineraryQualityControl}
    />
    <Route
      exact
      path={ITINERARY_FOR_SCHEDULING_PATH}
      component={ItinerariesForScheduling}
    />
    <Route exact path={SERVICE_MERGE_PATH} component={ServiceMerge} />
    <Route
      exact
      path={NEW_ITINERARY_SEARCH_OMISSION_PATH}
      component={NewItinerarySearchOmission}
    />
    <Route
      exact
      path={ITINERARY_SEARCH_OMISSION_PATH}
      component={ItinerarySearchOmissions}
    />
    <Route
      exact
      path={`${ITINERARY_SEARCH_OMISSION_PATH}/:id`}
      component={ItinerarySearchOmission}
    />
    <Route
      exact
      path={`${ITINERARY_SEARCH_OMISSION_PATH}/:id/edit`}
      component={EditItinerarySearchOmission}
    />
    {/* TODO list of extraordinary movement goes here */}
    <Route
      exact
      path={NEW_ITINERARY_EXTRAORDINARY_MOVEMENT_PATH}
      component={NewExtraordinaryMovement}
    />
    <Route
      exact
      path={generateRescueItineraryPath()}
      component={NewRescueItinerary}
    />
    <Route exact path={KIDS_ON_ITINERARY_PATH} component={KidsOnItinerary} />
    <Route exact path={SALES_CONTROL_PATH} component={ItinerarySalesControl} />
    <Route
      exact
      path={`${BILLINGS_ITINERARY_PATH}/:id`}
      component={BillingsItinerary}
    />
    <Route
      exact
      path={LIVE_SALES_CONTROL_PATH}
      component={LiveItinerarySalesControl}
    />
    <Route exact path={TRIP_REQUEST_PATH} component={TripRequests} />
    <Route exact path={NEW_TRIP_REQUEST_PATH} component={NewTripRequest} />
    <Route exact path={`${TRIP_REQUEST_PATH}/:id`} component={TripRequest} />
    <Route
      exact
      path={`${TRIP_REQUEST_PATH}/:id/edit`}
      component={EditTripRequest}
    />
    <Route exact path={PROCESS_PATH} component={Processes} />
    <Route exact path={NEW_PROCESS_PATH} component={NewProcess} />
    <Route exact path={`${PROCESS_PATH}/:id`} component={Process} />
    <Route
      exact
      path={`${PROCESS_PATH}/:id/edit-schedule`}
      component={EditProcessSchedule}
    />
    <Route exact path={`${PROCESS_PATH}/:id/edit`} component={EditProcess} />
    <Route
      exact
      path={generateItineraryByProcessStatusEditPath()}
      component={ProcessEditItineraryStatus}
    />
  </Switch>
);

export default ItineraryManagementModule;
