import Immutable from 'immutable';
import { COLLECTION_SHAPE } from '../../../config/shapes';
import {
  FLAG_GETTING_PARCELS,
  GET_PARCELS,
  CLEAR_PARCEL,
  FLAG_PARCEL_ACTIVITY,
  GET_PARCEL,
  POST_CARGO_PRICE,
  CLEAR_CARGO_PRICE,
  CLEAR_CARGO_PRICES,
  CLEAR_PARCELS,
  PRINT_CARGO_VOUCHER_ON_MOUNT,
} from '../../../actions/types/cargo';

const INITIAL_STATE = Immutable.Map({
  all: Immutable.Map({
    content: Immutable.Map(COLLECTION_SHAPE),
    loading: false,
  }),
  current: Immutable.Map({
    content: Immutable.Map(),
    activity: Immutable.List(),
    price: Immutable.Map(),
    printOnMount: false,
  }),
});

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case FLAG_GETTING_PARCELS:
      return state.setIn(['all', 'loading'], payload);
    case GET_PARCELS:
      return state.setIn(['all', 'content'], Immutable.Map(payload));
    case CLEAR_PARCELS:
      return state.setIn(
        ['all', 'content'],
        INITIAL_STATE.getIn(['all', 'content']),
      );
    case FLAG_PARCEL_ACTIVITY:
      if (payload) {
        return state.setIn(
          ['current', 'activity'],
          state.getIn(['current', 'activity']).push(payload),
        );
      }

      return state.setIn(
        ['current', 'activity'],
        state.getIn(['current', 'activity']).pop(),
      );
    case GET_PARCEL:
      return state.setIn(['current', 'content'], Immutable.Map(payload));
    case CLEAR_PARCEL:
      return state.setIn(['current'], INITIAL_STATE.getIn(['current']));
    case POST_CARGO_PRICE:
      return state.setIn(['current', 'price'], Immutable.Map(payload));
    case CLEAR_CARGO_PRICE:
      if (
        state
          .getIn(['current', 'price'])
          .findKey((price) => price.index === payload) !== undefined
      ) {
        const updatedPrices = state
          .getIn(['current', 'price'])
          .filter((price) => price.index !== payload)
          .map((price) => {
            if (price.index > payload) {
              return { ...price, index: price.index - 1 };
            }
            return price;
          });

        return state.setIn(['current', 'price'], updatedPrices);
      }

      return state;
    case CLEAR_CARGO_PRICES:
      return state.setIn(
        ['current', 'price'],
        INITIAL_STATE.getIn(['current', 'price']),
      );
    case PRINT_CARGO_VOUCHER_ON_MOUNT:
      return state.setIn(['current', 'printOnMount'], payload);

    default:
      return state;
  }
};
