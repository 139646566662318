import React, { useEffect, useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { Button } from 'reactstrap';
import { connect } from 'react-redux';
import { getTickets, clearTickets } from '../../../../actions/index';
import { TICKET_PATH } from '../../../../config/paths';
import { DEFAULT_PAGE_SIZE } from '../../../../config/constants';
import ModuleList from '../../../layout/ModuleList';
import { TICKETS_COLUMNS } from '../../../../config/columns';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import Loader from '../../../common/Loader';

export const Tickets = ({
  breadcrumbs,
  tickets,
  loading,
  dispatchGetTickets,
  dispatchClearTickets,
  isForMine,
}) => {
  const [query, setQuery] = useState(null);
  const [buttonContent, setButtonContent] = useState(
    'Mostrar todos los tickets',
  );
  const [date, setDate] = useState(null);
  const data = tickets.get('content') || [];
  const pages = tickets.get('totalPages') || null;
  const defaultPageSize = tickets.get('size') || DEFAULT_PAGE_SIZE;

  useLayoutEffect(() => {
    if (isForMine) {
      const currentDate = new Date();

      const formattedDate = new Intl.DateTimeFormat('es-PE', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      }).format(currentDate);

      setDate(formattedDate);

      setQuery(`departureDate>${formattedDate},ticketStatus:ACTIVE`);
    }

    return () => {
      dispatchClearTickets();
    };
  }, []);

  const onClickShow = () => {
    let newQuery;
    if (query !== '') {
      newQuery = '';
      setQuery(newQuery);
    } else {
      newQuery = `departureDate>${date},ticketStatus:ACTIVE`;
      setQuery(newQuery);
    }
    dispatchGetTickets({ query: newQuery });
  };

  useEffect(() => {
    if (query !== null) {
      if (query !== '') {
        setButtonContent('Mostrar todos los tickets');
      } else {
        setButtonContent('Mostrar tickets activos');
      }
    }
  }, [query]);

  const showButton = (
    <Button color="primary" onClick={onClickShow}>
      {buttonContent}
    </Button>
  );

  if (query === null && isForMine) {
    return <Loader />;
  }

  return (
    <>
      <ModuleList
        extraButtons={isForMine ? showButton : null}
        title="Boletos"
        breadcrumbs={breadcrumbs}
        tableStructure={{
          columns: TICKETS_COLUMNS,
          data,
          pages,
          defaultPageSize,
          filterable: true,
          fetchData: dispatchGetTickets,
          modelPath: TICKET_PATH,
          loading,
          navigateToModelOnRowClick: true,
          queryProps: query,
        }}
      />
    </>
  );
};

const mapStateToProps = ({ ReservationUnit, authentication }) => ({
  breadcrumbs: [
    ...ReservationUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Boletos',
      href: TICKET_PATH,
    },
  ],
  tickets: ReservationUnit.Ticket.getIn(['all', 'content']),
  loading: ReservationUnit.Ticket.getIn(['all', 'loading']),
  isForMine: !!authentication.get('user').salesSessionUserId,
});

const mapDispatchToProps = {
  dispatchGetTickets: getTickets,
  dispatchClearTickets: clearTickets,
};

Tickets.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  tickets: PropTypes.instanceOf(Immutable.Map),
  loading: PropTypes.bool,
  dispatchGetTickets: PropTypes.func.isRequired,
  dispatchClearTickets: PropTypes.func.isRequired,
  isForMine: PropTypes.bool,
};

Tickets.defaultProps = {
  tickets: null,
  loading: false,
  isForMine: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(Tickets);
