import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { ListGroupItem, Row, Col } from 'reactstrap';
import './ItinerarySearchResult.css';
import { TIME_FORMAT } from '../../../../config/locale';
import { numberFormatter } from '../../../../utils/number';
import {
  getDayFromTimestamp,
  timeFormat,
  tzNormalizeDate,
} from '../../../../utils/date';
import { COMPANY_STAFF_TYPE } from '../../../../config/constants';

const ItinerarySearchResult = (props) => {
  const { itinerarySearchResult, index, isForMine } = props;

  const {
    seatMapDisplayName,
    lowestAvailablePrice,
    duration,
    arrivalTime,
    departureTime,
    origin,
    destination,
    availableSeats,
    itinerary: {
      route: { routeSegmentList },
      type,
      companyItineraryAditionalFields,
    },
  } = itinerarySearchResult;

  const route = routeSegmentList.map(
    (routSegment) => routSegment.segment.destinationLocation.name,
  );
  route.unshift(routeSegmentList[0].segment.sourceLocation.name);
  const fullRoute = route.join(' - ');

  // departure
  const formattedDepartureTime = tzNormalizeDate({
    date: departureTime,
    format: TIME_FORMAT,
  });

  const dayDepartureTime = getDayFromTimestamp(departureTime);

  // arrival
  const formattedArrivalTime = tzNormalizeDate({
    date: arrivalTime,
    format: TIME_FORMAT,
  });

  // duration
  const formattedDuration = timeFormat(duration);

  let seatMapLabel = seatMapDisplayName;
  let priceLabel = (
    <Fragment>
      Desde
      <strong>{numberFormatter({ value: lowestAvailablePrice })}</strong>
    </Fragment>
  );
  let typeLabel = <Fragment>Tipo: {type}</Fragment>;
  if (companyItineraryAditionalFields) {
    seatMapLabel =
      COMPANY_STAFF_TYPE[companyItineraryAditionalFields.staffType].label;
    priceLabel = companyItineraryAditionalFields.shift;
    typeLabel = null;
  }

  return (
    <ListGroupItem
      className="ItinerarySearchResult mb-3"
      onClick={() =>
        props.handleSelectItinerary({ itinerarySearchResult, index })
      }
    >
      <Row>
        <Col xs="12" sm="2" md="3">
          <div>
            {isForMine ? (
              ''
            ) : (
              <>
                <h5>{seatMapLabel}</h5>
                <h6>{priceLabel}</h6>
                <h6>{typeLabel}</h6>
              </>
            )}
            <h6>{`Asientos Disp. ${availableSeats}`}</h6>
            {isForMine ? '' : <h6>{`Duración: ${formattedDuration}`}</h6>}
            {isForMine ? <h6>{`Día: ${dayDepartureTime}`}</h6> : ''}
          </div>
        </Col>
        <Col xs="12" sm="10" md="9">
          <Row className="itineraryResult">
            <Col className="d-flex justify-content-around align-items-stretch">
              <div>
                <h5>{origin.name}</h5>
                <h6>{`${formattedDepartureTime}`}</h6>
              </div>
              <div>
                <i className="fa fa-arrow-right fa-2x" />
              </div>
              <div>
                <h5>{destination.name}</h5>
                <h6>{`${formattedArrivalTime}`}</h6>
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="d-flex justify-content-around">
              {`Ruta: ${fullRoute}`}
            </Col>
          </Row>
        </Col>
      </Row>
    </ListGroupItem>
  );
};

ItinerarySearchResult.propTypes = {
  index: PropTypes.number.isRequired,
  itinerarySearchResult: PropTypes.shape({
    seatMapDisplayName: PropTypes.string.isRequired,
    lowestAvailablePrice: PropTypes.number.isRequired,
    duration: PropTypes.number.isRequired,
    arrivalTime: PropTypes.number.isRequired,
    departureTime: PropTypes.number.isRequired,
    origin: PropTypes.PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
    destination: PropTypes.PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
    availableSeats: PropTypes.number.isRequired,
    itinerary: PropTypes.shape({
      route: PropTypes.shape({
        routeSegmentList: PropTypes.arrayOf(
          PropTypes.shape({
            segment: PropTypes.shape({
              sourceLocation: PropTypes.shape({ name: PropTypes.string }),
            }),
          }),
        ),
      }),
      type: PropTypes.string.isRequired,
      companyItineraryAditionalFields: PropTypes.shape({
        shift: PropTypes.string,
        staffType: PropTypes.string,
      }),
    }),
  }).isRequired,
  handleSelectItinerary: PropTypes.func.isRequired,
  isForMine: PropTypes.bool.isRequired,
};

export default ItinerarySearchResult;
