import React, { useLayoutEffect } from 'react';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { PROCESS_PATH } from '../../../../config/paths';
import EditProcessTimetableForm from './EditProcessTimetableForm';
import {
  clearProcess,
  getProcess,
  putProcessSchedule,
} from '../../../../actions';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import NoDataResource from '../../../common/resource/NoDataResource';
import Loader from '../../../common/Loader';
import Content from '../../../layout/Content';

export const EditProcessSchedule = ({
  breadcrumbs,
  dispatchClearProcess,
  dispatchGetProcess,
  dispatchPutProcessSchedule,
  loading,
  match: {
    params: { id: processId },
  },
  process,
}) => {
  useLayoutEffect(() => {
    dispatchGetProcess({ processId });

    return () => dispatchClearProcess();
  }, []);

  const onSubmit = (formValues) =>
    dispatchPutProcessSchedule(processId, formValues);

  let content;

  if (loading) content = <Loader />;
  else if (process.isEmpty())
    content = <NoDataResource returnPage={PROCESS_PATH} />;
  else {
    const processObj = process.toJS();

    content = (
      <EditProcessTimetableForm
        itineraryScheduleList={processObj.itineraryScheduleList}
        startDate={processObj.departureTime}
        onSubmit={onSubmit}
      />
    );
  }

  return (
    <Content
      breadcrumbs={breadcrumbs}
      title="Editar horarios del proceso"
      subtitle="Editar horarios del proceso"
      content={content}
    />
  );
};

EditProcessSchedule.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchClearProcess: PropTypes.func.isRequired,
  dispatchGetProcess: PropTypes.func.isRequired,
  dispatchPutProcessSchedule: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  match: matchPropTypes.isRequired,
  process: PropTypes.instanceOf(Immutable.Map).isRequired,
};

EditProcessSchedule.defaultProps = {
  loading: false,
};

const mapStateToProps = (
  { ItineraryUnit },
  {
    match: {
      params: { id },
    },
  },
) => ({
  breadcrumbs: [
    ...ItineraryUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Lista de Procesos',
      href: PROCESS_PATH,
    },
    {
      text: 'Ver',
      href: `${PROCESS_PATH}/${id}`,
    },
    {
      text: 'Editar',
      href: '',
    },
  ],
  process: ItineraryUnit.Process.getIn(['current', 'content']),
  loading: !ItineraryUnit.Process.getIn(['current', 'activity']).isEmpty(),
});

const mapDispatchToProps = {
  dispatchClearProcess: clearProcess,
  dispatchGetProcess: getProcess,
  dispatchPutProcessSchedule: putProcessSchedule,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditProcessSchedule);
