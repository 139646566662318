import React from 'react';
import PropTypes from 'prop-types';
import { toastr } from 'react-redux-toastr';
import { connect } from 'react-redux';
import { Form, FormGroup } from 'reactstrap';
import { change, Field, reduxForm } from 'redux-form';
import Loader from '../../../common/Loader';
import Select from '../../../common/forms/select/Select';
import FormItem from '../../../common/forms/FormItem';
import TextInput from '../../../common/forms/input/TextInput';
import FormFooter from '../../../common/forms/FormFooter';
import DatePicker from '../../../common/forms/input/DatePicker';
import ShiftSelect from '../../../common/forms/select/ShiftSelect';
import {
  isRequired,
  validateIntegerNumber,
} from '../../../../utils/validators';
import { tzNormalizeDate } from '../../../../utils/date';
import {
  COMPANY_BUS_SEAT_COUNT,
  COMPANY_STAFF_TYPE,
} from '../../../../config/constants';
import { enumToSelectOptions } from '../../../../utils/enum';
import DynamicForm from '../../../common/forms/DynamicForm';
import { PROCESS_ROUTES_FORM_COLUMNS } from '../../../../config/dynamicFormFields';
import { INT_DATE_FORMAT, TIME_FORMAT } from '../../../../config/locale';
import generateTimetableData from '../../../../utils/timetable';

export const EditProcessForm = ({
  loading,
  handleSubmit,
  onSubmit,
  routes,
  startDate,
}) => {
  const onHandleSubmit = (formValues) => {
    const {
      shift,
      registrationDate,
      closedDate,
      staffType,
      name,
      routes: formRoutes,
    } = formValues;
    if (
      routes.some(
        (route) =>
          formRoutes &&
          formRoutes.some(
            (formRoute) => formRoute.routeId.value === route.routeId,
          ),
      )
    ) {
      toastr.error(
        'Error',
        'No se puede agregar una ruta ya existente en el proceso',
      );
      return;
    }

    const formattedShift = shift
      .map((currentShift) => currentShift.value)
      .join(',');

    if (formattedShift === '') {
      toastr.error('Error', 'Ingrese al menos un regimen');
      return;
    }

    const registrationDateValue = tzNormalizeDate({ date: registrationDate });
    const closedDateValue = tzNormalizeDate({ date: closedDate });

    const companyItineraryRequestList = [];

    const companyItineraryRequest = {
      registrationDate: registrationDateValue,
      closedDate: closedDateValue,
      name,
      shift: formattedShift,
      staffType: staffType.value,
    };

    companyItineraryRequestList.push(companyItineraryRequest);

    routes.forEach((route) => {
      const passengerCount =
        formValues.currentRoutes && formValues.currentRoutes[route.routeId];
      if (passengerCount) {
        const quantity = Math.ceil(passengerCount / COMPANY_BUS_SEAT_COUNT);
        const newCompanyItineraryRequest = {
          registrationDate: registrationDateValue,
          closedDate: closedDateValue,
          name,
          routeId: route.routeId,
          quantity,
          shift: formattedShift,
          staffType: staffType.value,
        };
        companyItineraryRequestList.push(newCompanyItineraryRequest);
      }
    });

    if (formRoutes) {
      formRoutes.forEach(
        ({ routeId: currentRoute, passengerCount, departureTime }) => {
          const startDateformatted = startDate
            ? tzNormalizeDate({
                date: startDate,
                format: INT_DATE_FORMAT,
              })
            : tzNormalizeDate({ format: INT_DATE_FORMAT });

          const formattedDepartureTime = departureTime
            ? tzNormalizeDate({
                date: departureTime,
                format: TIME_FORMAT,
              })
            : tzNormalizeDate({ format: TIME_FORMAT });

          const startDateValue = tzNormalizeDate({
            date: `${startDateformatted} ${formattedDepartureTime}`,
          });

          const endDateformatted = startDate
            ? tzNormalizeDate({ date: startDate, format: INT_DATE_FORMAT })
            : tzNormalizeDate({ format: INT_DATE_FORMAT });

          const endDateValue = tzNormalizeDate({
            date: `${endDateformatted} ${formattedDepartureTime}`,
            addTime: { amount: 30, unit: 'seconds' },
          });

          const itineraryScheduleSegmentList = generateTimetableData(
            currentRoute.routeSegmentList,
            departureTime,
            startDate,
          );
          const quantity = Math.ceil(passengerCount / COMPANY_BUS_SEAT_COUNT);
          const newCompanyItineraryRequest = {
            itineraryScheduleSegmentList,
            endDate: endDateValue,
            startDate: startDateValue,
            registrationDate: registrationDateValue,
            closedDate: closedDateValue,
            name,
            routeId: currentRoute.value,
            shift: formattedShift,
            staffType: staffType.value,
            dailyRepeat: {},
            quantity,
            repeatEvery: '1',
          };
          companyItineraryRequestList.push(newCompanyItineraryRequest);
        },
      );
    }

    onSubmit({ companyItineraryRequestList });
  };

  if (loading) return <Loader />;

  return (
    <Form onSubmit={handleSubmit(onHandleSubmit)}>
      <FormGroup row>
        <FormItem label="Nombre" required>
          <Field
            name="name"
            component={TextInput}
            type="text"
            placeholder="Nombre"
            validate={[isRequired]}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Régimen" required>
          <Field
            name="shift"
            component={ShiftSelect}
            placeholder="Régimen"
            isMulti
            validate={[isRequired]}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Tipo" required>
          <Field
            name="staffType"
            value={COMPANY_STAFF_TYPE.HOLDERS.value}
            component={Select}
            options={enumToSelectOptions(COMPANY_STAFF_TYPE)}
            validate={[isRequired]}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Fecha de inscripción" required>
          <Field
            name="registrationDate"
            component={DatePicker}
            validate={[isRequired]}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Fecha de cierre" required>
          <Field
            name="closedDate"
            component={DatePicker}
            validate={[isRequired]}
          />
        </FormItem>
      </FormGroup>
      <DynamicForm
        name="routes"
        columns={PROCESS_ROUTES_FORM_COLUMNS}
        labelWithoutElements="No hay rutas seleccionadas"
      />
      <h5>Agregar pasajeros para la ruta (opcional)</h5>
      {routes.map((route) => (
        <FormGroup row key={route.routeId}>
          <FormItem label={route.routeName}>
            <Field
              name={`currentRoutes[${route.routeId}]`}
              component={TextInput}
              validate={[validateIntegerNumber]}
            />
          </FormItem>
        </FormGroup>
      ))}
      <FormFooter />
    </Form>
  );
};

EditProcessForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  routes: PropTypes.instanceOf(Array).isRequired,
  startDate: PropTypes.instanceOf(Date).isRequired,
};

const mapStateToProps = ({ ItineraryUnit }) => ({
  loading: !ItineraryUnit.Process.getIn(['current', 'activity']).isEmpty(),
});

const mapDispatchToProps = {
  dispatchChange: change,
};

const formComponent = reduxForm({
  form: 'EditProcessForm',
})(EditProcessForm);

export default connect(mapStateToProps, mapDispatchToProps)(formComponent);
