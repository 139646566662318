import QueryString from 'query-string';
import { toastr } from 'react-redux-toastr';
import { push } from 'react-router-redux';
import {
  DEFAULT_GET_CONFIG,
  DEFAULT_POST_CONFIG,
  DEFAULT_DELETE_CONFIG,
  DEFAULT_PUT_CONFIG,
} from '../../config/rest';
import { isErrorResponse } from '../../utils/error-handlers';
import {
  generateApprovePayment,
  generateRejectPayment,
  PAYMENT_ENDPOINT,
} from '../../config/endpoints';
import {
  CLEAR_PAYMENT,
  CLEAR_PAYMENTS,
  FLAG_GETTING_PAYMENTS,
  FLAG_PAYMENT_ACTIVITY,
  GET_PAYMENT,
  GET_PAYMENTS,
} from '../types';
import { PAYMENT_PATH } from '../../config/paths';

const flagGettingContractPayments = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_GETTING_PAYMENTS,
    payload: flag,
  });

const getContractPayments = async (tableFilters) => async (dispatch) => {
  try {
    dispatch(flagGettingContractPayments(true));
    const query = tableFilters;
    const url = `${PAYMENT_ENDPOINT}?${QueryString.stringify(query)}`;
    const promise = await fetch(url, DEFAULT_GET_CONFIG);
    await isErrorResponse(promise);
    const response = await promise.json();
    dispatch({
      type: GET_PAYMENTS,
      payload: response,
    });
  } catch ({ message }) {
    toastr.error('Error', message);
  } finally {
    dispatch(flagGettingContractPayments(false));
  }
};

const clearContractPayments = () => (dispatch) =>
  dispatch({
    type: CLEAR_PAYMENTS,
  });

const flagContractPaymentActivity = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_PAYMENT_ACTIVITY,
    payload: flag,
  });

const getContractPayment =
  async ({ paymentId }) =>
  async (dispatch) => {
    try {
      dispatch(flagContractPaymentActivity(true));
      const url = `${PAYMENT_ENDPOINT}/${paymentId}`;
      const promise = await fetch(url, DEFAULT_GET_CONFIG);
      await isErrorResponse(promise);
      const response = await promise.json();
      dispatch({
        type: GET_PAYMENT,
        payload: response,
      });
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagContractPaymentActivity(false));
    }
  };

const clearContractPayment = () => (dispatch) =>
  dispatch({
    type: CLEAR_PAYMENT,
  });

const postContractPayment =
  async ({
    bankName,
    accountNumber,
    operationNumber,
    paymentDate,
    amount,
    companyId,
    manualVoucherId,
    parcelList,
  }) =>
  async (dispatch) => {
    try {
      dispatch(flagContractPaymentActivity(true));
      const payload = {
        bankName,
        accountNumber,
        operationNumber,
        paymentDate,
        amount,
        companyId,
        manualVoucherId,
        parcelList,
      };
      const url = PAYMENT_ENDPOINT;
      const promise = await fetch(url, {
        ...DEFAULT_POST_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(promise);
      await promise.json();
      dispatch(push(PAYMENT_PATH));
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagContractPaymentActivity(false));
    }
  };

const deleteContractPayment =
  async ({ paymentId }) =>
  async (dispatch) => {
    try {
      dispatch(flagContractPaymentActivity(true));
      const url = `${PAYMENT_ENDPOINT}/${paymentId}`;
      const promise = await fetch(url, {
        ...DEFAULT_DELETE_CONFIG,
      });
      await isErrorResponse(promise);
      await promise.json();
      dispatch(push(PAYMENT_PATH));
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagContractPaymentActivity(false));
    }
  };

const putContractPayment =
  async (
    paymentId,
    { bankName, accountNumber, operationNumber, paymentDate },
  ) =>
  async (dispatch) => {
    try {
      dispatch(flagContractPaymentActivity(true));
      const payload = {
        id: paymentId,
        bankName,
        accountNumber,
        operationNumber,
        paymentDate,
      };
      const url = `${PAYMENT_ENDPOINT}/${paymentId}`;
      const promise = await fetch(url, {
        ...DEFAULT_PUT_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(promise);
      await promise.json();
      dispatch(push(PAYMENT_PATH));
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagContractPaymentActivity(false));
    }
  };

const postApproveContractPayment =
  async ({ paymentId }) =>
  async (dispatch) => {
    try {
      dispatch(flagContractPaymentActivity(true));
      const url = generateApprovePayment(paymentId);
      await fetch(url, {
        ...DEFAULT_POST_CONFIG,
      });
      dispatch(getContractPayment({ paymentId }));
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagContractPaymentActivity(false));
    }
  };

const postRejectContractPayment =
  async ({ paymentId }) =>
  async (dispatch) => {
    try {
      dispatch(flagContractPaymentActivity(true));
      const url = generateRejectPayment(paymentId);
      await fetch(url, {
        ...DEFAULT_POST_CONFIG,
      });
      dispatch(getContractPayment({ paymentId }));
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagContractPaymentActivity(false));
    }
  };

export {
  flagGettingContractPayments,
  getContractPayments,
  clearContractPayments,
  flagContractPaymentActivity,
  getContractPayment,
  postContractPayment,
  clearContractPayment,
  deleteContractPayment,
  putContractPayment,
  postApproveContractPayment,
  postRejectContractPayment,
};
