import React, { useEffect, useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { Field, change, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import { FormGroup } from 'reactstrap';
import { isRequired } from '../../../../utils/validators';
import {
  getItineraryFoodGroup,
  deselectSeat,
  clearCustomers,
  getCompanyBusinesses,
  getCompanyCustomers,
  clearCompanyBusinesses,
  clearCompanyCustomers,
} from '../../../../actions/';
import { DATE_TIME_FORMAT } from '../../../../config/locale';
import CustomerInputGroup from '../../../common/forms/CustomerInputGroup';
import Select from '../../../common/forms/select/Select';
import { orderBy } from '../../../../utils/array';
import FormItem from '../../../common/forms/FormItem';
import {
  DOCUMENT_TYPE_CHINALCO,
  RELATIONSHIP,
} from '../../../../config/constants';
import { enumToSelectOptions } from '../../../../utils/enum';
import TextInput from '../../../common/forms/input/TextInput';
import Badge from '../../../common/Badge';
import { tzNormalizeDate } from '../../../../utils/date';

const selector = formValueSelector('PassengersForm');

const hasValidSeat = ({ value }, allValues) => {
  let duplicateCount = 0;
  allValues.passengers.forEach((passenger) => {
    const { seatReservation } = passenger;
    if (seatReservation && seatReservation.value === value) {
      duplicateCount += 1;
    }
  });

  return duplicateCount >= 2 ? 'Asiento repetido' : undefined;
};

const PassengerForm = ({
  index,
  passenger,
  foodOptions,
  seatReservations,
  dispatchGetItineraryFoodGroup,
  dispatchChange,
  itineraryId,
  dispatchDeselectSeat,
  fields,
  seatSelectionChangesInProgress,
  selfServiceCustomer,
  dispatchClearCustomers,
  companyBusinesses,
  loadingBusinesses,
  companyCustomers,
  loadingCustomers,
  dispatchGetCompanyBusinesses,
  dispatchGetCompanyCustomers,
  dispatchClearCompanyCustomers,
  business,
  documentType,
}) => {
  const [underageDisabled, setUnderageDisabled] = useState(true);
  const [showUnderageExtraFields, setShowUnderageExtraFields] = useState(false);
  const [grownUpPassenger, setGrownUpPassenger] = useState(null);
  const [underagePassenger, setUnderagePassenger] = useState(null);
  const [relationship, setRelationship] = useState(null);

  const validateSelfServiceCustomer = () => {
    if (selfServiceCustomer) {
      dispatchChange(
        'PassengersForm',
        `${passenger}.documentType`,
        enumToSelectOptions(DOCUMENT_TYPE_CHINALCO)[0],
      );
      dispatchGetCompanyBusinesses();
    }
  };

  useLayoutEffect(() => {
    dispatchGetItineraryFoodGroup(itineraryId);
    validateSelfServiceCustomer();
  }, []);

  const setFirstCompany = () => {
    if (!business && companyBusinesses && companyBusinesses.length !== 0) {
      dispatchChange(
        'PassengersForm',
        `${passenger}.business`,
        companyBusinesses[0],
      );
    }
  };

  useEffect(() => {
    setFirstCompany();
  }, [companyBusinesses]);

  const onChangeGrownUpPassenger = (customer) => {
    dispatchClearCustomers();

    const selectedCustomer = customer && customer.firstName;

    if (!selectedCustomer) {
      dispatchChange('PassengersForm', `${passenger}.kidCustomer`, null);
      dispatchChange('PassengersForm', `${passenger}.relationship`, null);
    }

    setGrownUpPassenger(customer && customer.fullName);
    setUnderagePassenger(null);
    setRelationship(null);
    setShowUnderageExtraFields(false);
    setUnderageDisabled(!selectedCustomer);
  };

  const onChangeUnderagePassenger = (customer) => {
    const selectedCustomer = customer && customer.firstName;

    if (!selectedCustomer)
      dispatchChange('PassengersForm', `${passenger}.relationship`, null);

    setShowUnderageExtraFields(customer && customer.firstName);
    setRelationship(null);
    setUnderagePassenger(customer && customer.fullName);
  };

  const onChangeRelationship = (newRelationship) =>
    setRelationship(newRelationship && newRelationship.label);

  const onClickDeselectSeat = () => {
    const {
      value: seatReservationId,
      label: seatId,
      itineraryId: currentItineraryId,
    } = seatReservations[index];

    fields.remove(index);

    dispatchDeselectSeat({
      seatReservationId,
      currentItineraryId,
      seatId,
    });
  };

  const onChangeSelfServicePassenger = (inputValue) => {
    if (inputValue.length && inputValue.length >= 8) {
      dispatchGetCompanyCustomers({
        documentNumber: inputValue,
        documentTypeId: documentType.value,
        businessId: 0,
      });
    }
  };

  const onChangeSelfServiceDocumentTypeBusiness = () => {
    dispatchChange('PassengersForm', `${passenger}.passenger`, null);
    dispatchClearCompanyCustomers();
  };

  const selfServiceFields = (
    <>
      <FormGroup row>
        <FormItem label="Empresa">
          <Field
            name={`${passenger}.business`}
            component={Select}
            options={companyBusinesses}
            onChange={onChangeSelfServiceDocumentTypeBusiness}
            isLoading={loadingBusinesses}
            validate={[isRequired]}
            isDisabled
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Tipo de documento">
          <Field
            name={`${passenger}.documentType`}
            component={Select}
            options={enumToSelectOptions(DOCUMENT_TYPE_CHINALCO)}
            onChange={onChangeSelfServiceDocumentTypeBusiness}
            validate={[isRequired]}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Pasajero">
          <Field
            name={`${passenger}.passenger`}
            component={Select}
            options={companyCustomers}
            onInputChange={onChangeSelfServicePassenger}
            isLoading={loadingCustomers}
            validate={[isRequired]}
          />
        </FormItem>
      </FormGroup>
    </>
  );

  let foodInput = null;
  if (foodOptions) {
    foodInput = (
      <FormGroup row>
        <FormItem label="Alimento" required>
          <Field
            name={`${passenger}.food`}
            component={Select}
            options={foodOptions}
            validate={[isRequired]}
          />
        </FormItem>
      </FormGroup>
    );
  }

  let route = null;
  let deleteButton = null;
  if (seatReservations[index]) {
    const {
      itinerary: {
        originName,
        destinationName,
        serviceName,
        departureTime,
        arrivalTime,
      },
    } = seatReservations[index];

    // departure
    const formattedDepartureTime = tzNormalizeDate({
      date: departureTime,
      format: DATE_TIME_FORMAT,
    });

    // arrival
    const formattedArrivalTime = tzNormalizeDate({
      date: arrivalTime,
      format: DATE_TIME_FORMAT,
    });

    route = (
      <>
        <div className="mr-2">({serviceName})</div>
        <div className="text-center">
          {originName}{' '}
          <p>
            <small>{formattedDepartureTime}</small>
          </p>
        </div>
        <div className="mr-3 ml-3"> &rarr; </div>
        <div className="text-center">
          {destinationName}{' '}
          <p>
            <small>{formattedArrivalTime}</small>
          </p>
        </div>
      </>
    );

    let deselectSeatFunction = null;

    if (seatSelectionChangesInProgress.size === 0) {
      deselectSeatFunction = onClickDeselectSeat;
    }

    deleteButton = (
      <i
        className="fa fa-times fa-2x text-danger clickable"
        role="button"
        onClick={deselectSeatFunction}
        onKeyPress={deselectSeatFunction}
        tabIndex={0}
      />
    );
  }

  const header = (
    <div className="clearfix">
      <div className="float-left">
        <h5>
          <div className="d-flex">
            <div className="mr-3">Pasajero {index + 1}: </div> {route}
          </div>
        </h5>
      </div>
      <div className="float-right">{deleteButton}</div>
    </div>
  );

  const underageExtraFields = showUnderageExtraFields && (
    <>
      <FormGroup row>
        <FormItem label="Relación con el Menor">
          <Field
            name={`${passenger}.relationship`}
            component={Select}
            options={enumToSelectOptions(RELATIONSHIP)}
            onChange={onChangeRelationship}
            validate={[isRequired]}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Observaciones">
          <Field
            name={`${passenger}.comments`}
            component={TextInput}
            type="textarea"
            placeholder="Observaciones"
          />
        </FormItem>
      </FormGroup>
    </>
  );

  const relationshipText =
    RELATIONSHIP.OTHER.label !== relationship
      ? `${grownUpPassenger} es ${relationship} de ${underagePassenger}`
      : `${grownUpPassenger} tiene otro tipo de relación con ${underagePassenger}`;
  const underageDetails = underagePassenger &&
    grownUpPassenger &&
    relationship && (
      <Badge color="success" text={<small>{relationshipText}</small>} />
    );

  return (
    <div>
      <hr />
      {header}
      {!selfServiceCustomer ? (
        <CustomerInputGroup
          label="Pasajero"
          labelRequired
          name={`${passenger}.passenger`}
          form="PassengersForm"
          mobilePhoneRequired
          onChange={onChangeGrownUpPassenger}
          showDetails={!selfServiceCustomer}
          showDiscountMessage
          searchByDocumentNumber={selfServiceCustomer}
        />
      ) : (
        selfServiceFields
      )}
      <FormGroup row>
        <FormItem label="Asiento" required>
          <Field
            name={`${passenger}.seatReservation`}
            component={Select}
            options={seatReservations}
            isDisabled
            validate={[isRequired, hasValidSeat]}
          />
        </FormItem>
      </FormGroup>
      {foodInput}
      {!selfServiceCustomer ? (
        <div className="mr-3 ml-3 border rounded shadow-sm">
          <div className="pt-3 pr-3 pl-3 pb-1">
            <h5>Datos del Menor de Edad: {underageDetails}</h5>
            <CustomerInputGroup
              label="Menor de Edad"
              name={`${passenger}.kidCustomer`}
              form="PassengersForm"
              disabled={underageDisabled}
              onChange={onChangeUnderagePassenger}
              showDetails
              isClearable
            />
            {underageExtraFields}
          </div>
        </div>
      ) : null}
    </div>
  );
};

PassengerForm.propTypes = {
  index: PropTypes.number.isRequired,
  passenger: PropTypes.string.isRequired,
  foodOptions: Select.propTypes.options,
  seatReservations: Select.propTypes.options.isRequired,
  dispatchGetItineraryFoodGroup: PropTypes.func.isRequired,
  dispatchChange: PropTypes.func.isRequired,
  itineraryId: PropTypes.number.isRequired,
  dispatchDeselectSeat: PropTypes.func.isRequired,
  fields: PropTypes.instanceOf(Object).isRequired,
  seatSelectionChangesInProgress: PropTypes.instanceOf(Immutable.Set)
    .isRequired,
  selfServiceCustomer: PropTypes.shape({
    value: PropTypes.number,
    label: PropTypes.string,
    salesSessionUserId: PropTypes.number,
  }),
  dispatchClearCustomers: PropTypes.func.isRequired,
  companyBusinesses: Select.propTypes.options.isRequired,
  loadingBusinesses: PropTypes.bool,
  companyCustomers: Select.propTypes.options.isRequired,
  loadingCustomers: PropTypes.bool,
  dispatchGetCompanyBusinesses: PropTypes.func.isRequired,
  dispatchGetCompanyCustomers: PropTypes.func.isRequired,
  dispatchClearCompanyCustomers: PropTypes.func.isRequired,
  business: PropTypes.instanceOf(Object),
  documentType: PropTypes.instanceOf(Object),
};

PassengerForm.defaultProps = {
  selfServiceCustomer: null,
  foodOptions: null,
  loadingBusinesses: false,
  loadingCustomers: false,
  business: null,
  documentType: null,
};

const mapStateToProps = (state, { index, passenger }) => {
  const seatReservations = state.BookingUnit.Booking.getIn([
    'seats',
    'seatReservations',
  ])
    .map((seatReservation) => ({
      value: seatReservation.id,
      label: seatReservation.seat.seatNumber,
      itinerary: seatReservation.itinerary || null,
      itineraryId: seatReservation.itineraryId || null,
      seatNumber: seatReservation.seat.seatNumber,
      floorNumber: seatReservation.seat.floorNumber,
    }))
    .toJS();

  const seatReservationsOrdered = orderBy(
    seatReservations,
    ['itinerary.departureTime', 'itineraryId', 'label'],
    ['asc'],
  );

  const foodGroup = state.BookingUnit.Booking.getIn([
    'passengers',
    'itineraryFoodGroup',
  ]);

  let foodOptions = null;
  if (foodGroup && foodGroup.foodSet) {
    foodOptions = foodGroup.foodSet.map((food) => ({
      value: food.id,
      label: food.name,
    }));
  }

  const seatSelectionChangesInProgress = state.BookingUnit.Booking.getIn([
    'seats',
    'seatSelectionChangesInProgress',
  ]);

  const { customer, salesSessionUserId } = state.authentication.get('user');

  let selfServiceCustomer;

  if (salesSessionUserId) {
    selfServiceCustomer = {
      value: customer.id,
      label: `${customer.idDocumentNumber} - ${customer.fullName}`,
    };
  }

  const business = selector(state, `${passenger}.business`);
  const documentType = selector(state, `${passenger}.documentType`);

  const companyBusinesses = state.ContractUnit.Company.getIn([
    'current',
    'businesses',
  ]).toJS();

  const loadingBusinesses = !state.ContractUnit.Company.getIn([
    'current',
    'activityBusinesses',
  ]).isEmpty();

  const companyCustomers = state.ContractUnit.Company.getIn([
    'current',
    'customers',
  ]).toJS();

  const loadingCustomers = !state.ContractUnit.Company.getIn([
    'current',
    'activityCustomers',
  ]).isEmpty();

  const { itineraryId } = seatReservations[index];

  return {
    seatReservations: seatReservationsOrdered,
    foodOptions,
    itineraryId,
    seatSelectionChangesInProgress,
    selfServiceCustomer,
    companyBusinesses,
    loadingBusinesses,
    companyCustomers,
    loadingCustomers,
    documentType,
    business,
  };
};

const mapDispatchToProps = {
  dispatchGetItineraryFoodGroup: getItineraryFoodGroup,
  dispatchChange: change,
  dispatchDeselectSeat: deselectSeat,
  dispatchClearCustomers: clearCustomers,
  dispatchGetCompanyBusinesses: getCompanyBusinesses,
  dispatchClearCompanyBusinesses: clearCompanyBusinesses,
  dispatchGetCompanyCustomers: getCompanyCustomers,
  dispatchClearCompanyCustomers: clearCompanyCustomers,
};

export default connect(mapStateToProps, mapDispatchToProps)(PassengerForm);
