import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import TableToolbar from '../common/TableToolbar';
import Alert from '../common/informative/Alert';
import Table from '../common/Table';
import { breadcrumbsPropTypes } from '../common/resource/proptypes/CommonPropTypes';
import Content from './Content';

const ModuleList = ({
  title,
  description,
  breadcrumbs,
  buttonPath,
  buttonText,
  error,
  tableStructure,
  isExternalPath,
  extraButtons,
}) => {
  const toolBar =
    buttonPath || extraButtons ? (
      <TableToolbar
        extraButtons={extraButtons}
        isExternalPath={isExternalPath}
        buttonPath={buttonPath}
        buttonText={buttonText}
      />
    ) : null;

  const descriptionComponent = description || null;

  const informationComponent = (
    <Fragment>
      <i className="fa fa-info-circle" /> Presiona la tecla{' '}
      <kbd>Enter &#10550;</kbd> en las cajas de texto del filtro de la tabla
      para realizar la búsqueda.
    </Fragment>
  );

  let tableComponent;

  if (error) {
    tableComponent = <Alert message={error} />;
  } else {
    tableComponent = (
      <Table
        columns={tableStructure.columns}
        data={tableStructure.data}
        pages={tableStructure.pages}
        defaultPageSize={tableStructure.defaultPageSize}
        fetchData={tableStructure.fetchData}
        modelPath={tableStructure.modelPath}
        isExternalPath={tableStructure.isExternalPath}
        loading={tableStructure.loading}
        filterable={tableStructure.filterable}
        navigateToModelOnRowClick={tableStructure.navigateToModelOnRowClick}
        rowComponent={tableStructure.rowComponent}
        generateStyle={tableStructure.generateStyle}
        queryProps={tableStructure.queryProps}
      />
    );
  }

  const subtitle = (
    <Fragment>
      {descriptionComponent}
      {informationComponent}
    </Fragment>
  );

  return (
    <Content
      breadcrumbs={breadcrumbs}
      toolbar={toolBar}
      title={title}
      subtitle={subtitle}
      content={tableComponent}
    />
  );
};

ModuleList.propTypes = {
  breadcrumbs: breadcrumbsPropTypes,
  buttonPath: PropTypes.string,
  buttonText: PropTypes.string,
  isExternalPath: PropTypes.bool,
  title: PropTypes.string.isRequired,
  extraButtons: PropTypes.instanceOf(Object),
  description: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  error: PropTypes.string,
  tableStructure: PropTypes.shape({
    columns: PropTypes.arrayOf(
      PropTypes.shape({
        Header: PropTypes.string,
        accessor: PropTypes.string,
      }),
    ).isRequired,
    data: PropTypes.arrayOf(
      PropTypes.shape({
        content: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.number,
          }),
        ),
        first: PropTypes.bool,
        last: PropTypes.bool,
        number: PropTypes.number,
        numberOfElements: PropTypes.number,
        size: PropTypes.number,
        sort: PropTypes.arrayOf(
          PropTypes.shape({
            direction: PropTypes.string,
            property: PropTypes.string,
            ignoreCase: PropTypes.bool,
            nullHandling: PropTypes.string,
            descending: PropTypes.bool,
          }),
        ),
        totalElements: PropTypes.number,
        totalPages: PropTypes.number,
      }),
    ),
    pages: PropTypes.number,
    defaultPageSize: PropTypes.number,
    fetchData: PropTypes.func,
    modelPath: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
    loading: PropTypes.bool,
    navigateToModelOnRowClick: PropTypes.bool,
    isExternalPath: PropTypes.bool,
    filterable: PropTypes.bool,
    generateStyle: PropTypes.func,
    rowComponent: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
    queryProps: PropTypes.string,
  }).isRequired,
};

ModuleList.defaultProps = {
  breadcrumbs: null,
  buttonText: undefined,
  buttonPath: null,
  isExternalPath: false,
  description: null,
  error: null,
  extraButtons: null,
};

export default ModuleList;
