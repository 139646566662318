import Immutable from 'immutable';
import {
  FLAG_GETTING_ITINERARIES,
  GET_ITINERARIES,
  CLEAR_ITINERARIES,
  FLAG_ITINERARY_ACTIVITY,
  GET_ITINERARY,
  CLEAR_ITINERARY,
  GET_LIST_DRIVERS,
  CLEAR_LIST_DRIVERS,
  GET_PASSENGER_FLOW,
  CLEAR_PASSENGER_FLOW,
  GET_CURRENT_USER_SPOT_IN_ITINERARY,
  CLEAR_CURRENT_USER_SPOT_IN_ITINERARY,
  FLAG_GETTING_ITINERARY_PROMISES,
  GET_ITINERARY_PROMISES,
  CLEAR_ITINERARY_PROMISES,
  FLAG_GETTING_PASSENGER_FLOW,
  GET_COMPANY_ITINERARY_DEFAULT_FIELDS,
  GET_PERSONNEL_FOR_ITINERARY,
  CLEAR_PERSONNEL_FOR_ITINERARY,
  FLAG_PERSONNEL_ACTIVITY,
} from '../../../actions/types';
import { COLLECTION_SHAPE } from '../../../config/shapes';

const INITIAL_STATE = Immutable.Map({
  all: Immutable.Map({
    content: Immutable.Map(COLLECTION_SHAPE),
    loading: false,
  }),
  current: Immutable.Map({
    content: Immutable.Map(),
    activity: Immutable.List(),
    drivers: Immutable.Set(),
    passengerFlow: Immutable.Map(),
    userSpot: null,
    flowLoading: false,
    companyItineraryDefaultFields: Immutable.Map(),
    personnel: Immutable.Set(),
    personnelActivity: Immutable.List(),
  }),
  currentPromises: Immutable.Map({
    content: Immutable.Set(),
    loading: false,
  }),
});

export default (state = INITIAL_STATE, { payload, type }) => {
  switch (type) {
    case FLAG_GETTING_ITINERARIES:
      return state.setIn(['all', 'loading'], payload);
    case GET_ITINERARIES:
      return state.setIn(['all', 'content'], Immutable.Map(payload));
    case CLEAR_ITINERARIES:
      return state.setIn(
        ['all', 'content'],
        INITIAL_STATE.getIn(['all', 'content']),
      );
    case FLAG_ITINERARY_ACTIVITY:
      if (payload) {
        return state.setIn(
          ['current', 'activity'],
          state.getIn(['current', 'activity']).push(payload),
        );
      }

      return state.setIn(
        ['current', 'activity'],
        state.getIn(['current', 'activity']).pop(),
      );
    case GET_ITINERARY:
      return state.setIn(['current', 'content'], Immutable.Map(payload));
    case CLEAR_ITINERARY:
      return state.setIn(['current'], INITIAL_STATE.getIn(['current']));
    case GET_LIST_DRIVERS:
      return state.setIn(['current', 'drivers'], Immutable.Set(payload));
    case CLEAR_LIST_DRIVERS:
      return state.setIn(
        ['current', 'drivers'],
        INITIAL_STATE.getIn(['current', 'drivers']),
      );
    case GET_PASSENGER_FLOW:
      return state.setIn(['current', 'passengerFlow'], Immutable.Map(payload));
    case CLEAR_PASSENGER_FLOW:
      return state.setIn(
        ['current', 'passengerFlow'],
        INITIAL_STATE.getIn(['current', 'passengerFlow']),
      );
    case GET_CURRENT_USER_SPOT_IN_ITINERARY:
      return state.setIn(['current', 'userSpot'], payload);
    case CLEAR_CURRENT_USER_SPOT_IN_ITINERARY:
      return state.setIn(
        ['current', 'userSpot'],
        INITIAL_STATE.getIn(['current', 'userSpot']),
      );
    case FLAG_GETTING_ITINERARY_PROMISES:
      return state.setIn(['currentPromises', 'loading'], payload);
    case GET_ITINERARY_PROMISES:
      return state.setIn(
        ['currentPromises', 'content'],
        Immutable.Set(payload),
      );
    case CLEAR_ITINERARY_PROMISES:
      return state.setIn(
        ['currentPromises', 'content'],
        INITIAL_STATE.getIn(['currentPromises', 'content']),
      );
    case FLAG_GETTING_PASSENGER_FLOW:
      return state.setIn(['current', 'flowLoading'], payload);
    case GET_COMPANY_ITINERARY_DEFAULT_FIELDS:
      return state.setIn(
        ['current', 'companyItineraryDefaultFields'],
        Immutable.Map(payload),
      );
    case GET_PERSONNEL_FOR_ITINERARY:
      return state.setIn(['current', 'personnel'], Immutable.Set(payload));
    case CLEAR_PERSONNEL_FOR_ITINERARY:
      return state.setIn(
        ['current', 'personnel'],
        INITIAL_STATE.getIn(['current', 'personnel']),
      );
    case FLAG_PERSONNEL_ACTIVITY:
      if (payload) {
        return state.setIn(
          ['current', 'personnelActivity'],
          state.getIn(['current', 'personnelActivity']).push(payload),
        );
      }

      return state.setIn(
        ['current', 'personnelActivity'],
        state.getIn(['current', 'personnelActivity']).pop(),
      );
    default:
      return state;
  }
};
