import QueryString from 'query-string';
import { toastr } from 'react-redux-toastr';
import { push } from 'react-router-redux';
import { CONTRACTOR_ENDPOINT } from '../../config/endpoints';
import {
  DEFAULT_DELETE_CONFIG,
  DEFAULT_GET_CONFIG,
  DEFAULT_POST_CONFIG,
  DEFAULT_PUT_CONFIG,
} from '../../config/rest';
import { isErrorResponse } from '../../utils/error-handlers';
import {
  CLEAR_CONTRACTOR,
  CLEAR_CONTRACTORS,
  FLAG_CONTRACTOR_ACTIVITY,
  FLAG_GETTING_CONTRACTORS,
  GET_CONTRACTOR,
  GET_CONTRACTORS,
} from '../types';
import { CONTRACTOR_PATH, TRIP_REQUEST_PATH } from '../../config/paths';

const flagGettingContractors = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_GETTING_CONTRACTORS,
    payload: flag,
  });

const getContractors = async (tableFilters) => async (dispatch) => {
  try {
    dispatch(flagGettingContractors(true));
    const query = tableFilters;
    const url = `${CONTRACTOR_ENDPOINT}?${QueryString.stringify(query)}`;
    const promise = await fetch(url, DEFAULT_GET_CONFIG);
    await isErrorResponse(promise);
    const response = await promise.json();
    dispatch({
      type: GET_CONTRACTORS,
      payload: response,
    });
  } catch ({ message }) {
    toastr.error('Error', message);
  } finally {
    dispatch(flagGettingContractors(false));
  }
};

const clearContractors = () => (dispatch) =>
  dispatch({
    type: CLEAR_CONTRACTORS,
  });

const flagContractorActivity = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_CONTRACTOR_ACTIVITY,
    payload: flag,
  });

const getContractor =
  async ({ contractorId }) =>
  async (dispatch) => {
    try {
      dispatch(flagContractorActivity(true));
      const url = `${CONTRACTOR_ENDPOINT}/${contractorId}`;
      const promise = await fetch(url, DEFAULT_GET_CONFIG);
      await isErrorResponse(promise);
      const response = await promise.json();
      dispatch({
        type: GET_CONTRACTOR,
        payload: response,
      });
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagContractorActivity(false));
    }
  };

const clearContractor = () => (dispatch) =>
  dispatch({
    type: CLEAR_CONTRACTOR,
  });

const postContractor =
  async ({ customerId, businessId, companyId, password }) =>
  async (dispatch) => {
    try {
      dispatch(flagContractorActivity(true));
      const payload = {
        customerId,
        businessId,
        companyId,
        password,
      };
      const url = CONTRACTOR_ENDPOINT;
      const promise = await fetch(url, {
        ...DEFAULT_POST_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(promise);
      await promise.json();
      dispatch(push(CONTRACTOR_PATH));
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagContractorActivity(false));
    }
  };

const deleteContractor =
  async ({ contractorId }) =>
  async (dispatch) => {
    try {
      dispatch(flagContractorActivity(true));
      const url = `${CONTRACTOR_ENDPOINT}/${contractorId}`;
      const promise = await fetch(url, {
        ...DEFAULT_DELETE_CONFIG,
      });
      await isErrorResponse(promise);
      await promise.json();
      dispatch(push(CONTRACTOR_PATH));
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagContractorActivity(false));
    }
  };

const putContractor =
  async (contractorId, { name, externalId, measurementUnit }) =>
  async (dispatch) => {
    try {
      dispatch(flagContractorActivity(true));
      const payload = {
        id: contractorId,
        name,
        externalId,
        measurementUnit,
      };
      const url = `${CONTRACTOR_ENDPOINT}/${contractorId}`;
      const promise = await fetch(url, {
        ...DEFAULT_PUT_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(promise);
      await promise.json();
      dispatch(push(CONTRACTOR_PATH));
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagContractorActivity(false));
    }
  };

const getCurrentContractor = async () => async (dispatch) => {
  try {
    dispatch(flagContractorActivity(true));
    const url = `${CONTRACTOR_ENDPOINT}/actions/get-current`;
    const promise = await fetch(url, DEFAULT_GET_CONFIG);
    await isErrorResponse(promise);
    const response = await promise.json();
    dispatch({
      type: GET_CONTRACTOR,
      payload: response,
    });
  } catch ({ message }) {
    toastr.error('Error', message);
    dispatch(push(TRIP_REQUEST_PATH));
  } finally {
    dispatch(flagContractorActivity(false));
  }
};

export {
  flagGettingContractors,
  getContractors,
  clearContractors,
  flagContractorActivity,
  getContractor,
  postContractor,
  clearContractor,
  deleteContractor,
  putContractor,
  getCurrentContractor,
};
