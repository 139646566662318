import React, { useEffect, useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { toastr } from 'react-redux-toastr';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { Form, FormGroup, Button, Label } from 'reactstrap';
import { Field, reduxForm, change, formValueSelector } from 'redux-form';
import {
  postCargoPrice,
  clearCargoPrices,
  clearCargoPrice,
  getActiveSalesSession,
  getDetractionAmountBySalePrice,
} from '../../../../actions';
import {
  isRequired,
  validateNumber,
  validateMaxLength,
} from '../../../../utils/validators';
import { CARGO_PATH } from '../../../../config/paths';
import { generateCargoItemColumns } from '../../../../config/dynamicFormFields';
import { VOUCHER_TYPE_BUSINESS_INVOICE_ID } from '../../../../config/constants';
import Alert from '../../../common/informative/Alert';
import Loader from '../../../common/Loader';
import Select from '../../../common/forms/select/Select';
import FormItem from '../../../common/forms/FormItem';
import TextInput from '../../../common/forms/input/TextInput';
import FormFooter from '../../../common/forms/FormFooter';
import DynamicForm from '../../../common/forms/DynamicForm';
import VoucherTypeSelect from '../../../common/forms/select/VoucherTypeSelect';
import BusinessInputGroup from '../../../common/forms/BusinessInputGroup';
import CustomerInputGroup from '../../../common/forms/CustomerInputGroup';
import CargoLocationSelect from '../../../common/forms/select/CargoLocationSelect';
import PaymentMethodInputGroup from '../../../common/forms/PaymentMethodInputGroup';
import './ParcelForm.css';
import { DEFAULT_VOUCHER_TYPE_OPTION } from '../../../../config/defaults';

const selector = formValueSelector('ParcelForm');
const validataMaxLength10 = validateMaxLength(10);
const validateSecretCode = (confirmSecretCode, { secretCode }) => {
  if (!confirmSecretCode) return undefined;

  return confirmSecretCode !== secretCode
    ? 'Las claves secretas no coinciden'
    : undefined;
};

export const ParcelForm = ({
  prices,
  salePriceValue,
  cargaItemsValues,
  selectedVoucherType,
  senderCustomerValue,
  onSubmit,
  handleSubmit,
  dispatchChange,
  loading,
  commonProducts,
  activeSalesSession,
  gettingActiveSalesSession,
  dispatchPostCargoPrice,
  dispatchClearCargoPrices,
  dispatchClearCargoPrice,
  dispatchGetActiveSalesSession,
  dispatchGetDetractionAmountBySalePrice,
  discountCode,
  dispatchPush,
}) => {
  const [showDeliveryFields, setShowDeliveryFields] = useState(false);
  const [sourceCityId, setSourceCityId] = useState(0);
  const [destinationCityId, setDestinationCityId] = useState(0);
  const [showDetraction, setShowDetraction] = useState(false);
  const [detraction, setDetraction] = useState(0);
  const [showLoader, setShowLoader] = useState(false);
  const [isCopyClientChecked, setIsCopyClientChecked] = useState(false);
  const [isDiscountCodeChecked, setIsDiscountCodeChecked] = useState(false);
  const [showSecondaryConsigneeField, SetShowSecondaryConsigneeField] =
    useState(false);

  useLayoutEffect(() => {
    dispatchGetActiveSalesSession({ source: CARGO_PATH });
    return () => dispatchClearCargoPrices();
  }, []);

  useEffect(() => {
    if (
      selectedVoucherType &&
      selectedVoucherType.value !== VOUCHER_TYPE_BUSINESS_INVOICE_ID
    ) {
      setDetraction(0);
      setShowDetraction(false);
    }
  }, [selectedVoucherType]);

  useEffect(() => {
    setShowLoader(gettingActiveSalesSession || activeSalesSession.isEmpty());
    if (!activeSalesSession.isEmpty()) {
      const {
        locationId,
        location: {
          name,
          address: { city },
          receiveCargoPoint,
        },
      } = activeSalesSession.get('agency');
      if (!receiveCargoPoint) {
        toastr.error('Error', 'La agencia no puede recibir carga.');
        dispatchPush(CARGO_PATH);
        return;
      }
      const sourceLocation = { value: locationId, label: name, city };
      setSourceCityId(city.id);
      dispatchChange('ParcelForm', 'sourceLocation', sourceLocation);
    }
  }, [gettingActiveSalesSession, activeSalesSession]);

  useEffect(() => {
    if (!prices.isEmpty()) {
      const salePriceWithDetraction = prices.get('finalPrice') - detraction;
      dispatchChange('ParcelForm', 'detraction', detraction.toFixed(2));
      dispatchChange(
        'ParcelForm',
        'salePriceWithDetraction',
        salePriceWithDetraction.toFixed(2),
      );
    }
  }, [detraction, prices]);

  const calculateTotalListPrice = () => {
    let total = 0;

    prices.get('pricesList').forEach(({ price: { listPrice } }) => {
      total += listPrice;
    });

    dispatchChange('ParcelForm', 'listPrice', total.toFixed(2));
  };

  const calculateTotalSalePrice = async () => {
    const total = prices.get('totalPrice');
    if (
      selectedVoucherType &&
      selectedVoucherType.value === VOUCHER_TYPE_BUSINESS_INVOICE_ID
    ) {
      const updatedDetraction = await dispatchGetDetractionAmountBySalePrice({
        salePrice: total,
      });
      setDetraction(updatedDetraction);
      setShowDetraction(updatedDetraction !== 0);
    }
    dispatchChange('ParcelForm', 'salePrice', total.toFixed(2));
    if (isDiscountCodeChecked) {
      dispatchChange(
        'ParcelForm',
        'discountPrice',
        prices.get('finalPrice').toFixed(2),
      );
    }
  };

  const showItemListPrice = () => {
    prices
      .get('pricesList')
      .forEach(({ index, price: { listPrice, salePrice } }) => {
        dispatchChange(
          'ParcelForm',
          `cargaItems[${index}].listPrice`,
          listPrice.toFixed(2),
        );
        dispatchChange(
          'ParcelForm',
          `cargaItems[${index}].salePrice`,
          salePrice,
        );
      });

    calculateTotalListPrice();
    calculateTotalSalePrice();
  };

  useEffect(() => {
    if (!prices.isEmpty()) {
      showItemListPrice();
    }
  }, [prices, salePriceValue]);

  const reinicializeItemPrices = (index) => {
    dispatchClearCargoPrice(index);
    dispatchChange('ParcelForm', `cargaItems[${index}].listPrice`, '');
    dispatchChange('ParcelForm', `cargaItems[${index}].salePrice`, '');
  };

  const onClickRemove = (index, fields) => {
    fields.remove(index);
    dispatchClearCargoPrice(index);
  };

  const updateCommonProductPrice = (index, payload) => {
    const actualQuantity =
      typeof payload === 'string' ? payload : cargaItemsValues[index].quantity;
    const actualCommonProduct =
      typeof payload === 'object'
        ? payload
        : cargaItemsValues[index].commonProductId;
    if (
      !actualCommonProduct ||
      !actualCommonProduct.value ||
      !actualQuantity ||
      Number.isNaN(Number(actualQuantity))
    )
      return;
    const matchingProduct = commonProducts.find(
      (product) => product.id === actualCommonProduct.value,
    );
    const commonProductPrice = actualQuantity * matchingProduct.shippingPrice;
    dispatchChange(
      'ParcelForm',
      `cargaItems[${index}].listPrice`,
      commonProductPrice.toFixed(2),
    );
    dispatchChange(
      'ParcelForm',
      `cargaItems[${index}].salePrice`,
      commonProductPrice,
    );
  };

  const onQuantityInputChange = (index, e) => {
    reinicializeItemPrices(index);
    updateCommonProductPrice(index, e.target.value);
  };

  const onCommonProductChange = (index, e) => {
    reinicializeItemPrices(index);
    updateCommonProductPrice(index, e);
  };

  const onWeightChange = (index) => {
    if (cargaItemsValues[index].commonProductId) return;
    reinicializeItemPrices(index);
  };

  const validateItemValues = (itemsValues) => {
    let isValid = true;
    if (itemsValues.length) {
      const isInvalidData = itemsValues.some((itemValue) => {
        if (!itemValue.quantity || itemValue.quantity <= 0) {
          toastr.error('Error', 'Ingrese una cantidad válida.');
          return true;
        }
        if (!itemValue.weight) {
          toastr.error('Error', 'Ingrese un peso válido.');
          return true;
        }
        return false;
      });
      isValid = !isInvalidData;
    } else {
      toastr.error('Error', 'Ingrese al menos un item al detalle de carga.');
      isValid = false;
    }
    return isValid;
  };

  const calculateItemListPrice = async () => {
    if (validateItemValues(cargaItemsValues)) {
      dispatchClearCargoPrices();
      const indexes = [];
      const cargoItemList = [];
      cargaItemsValues.forEach((item, index) => {
        const itemToList = {
          quantity: item.quantity,
          weight: item.weight,
        };

        if (item.commonProductId) {
          itemToList.commonProductId = item.commonProductId.value;
        }
        indexes.push(index);
        cargoItemList.push(itemToList);
      });
      const request = {
        sourceCityId,
        destinationCityId,
        cargoItemList,
        discountCodeString: isDiscountCodeChecked ? discountCode : null,
      };

      const response = await dispatchPostCargoPrice(indexes, request);

      if (response === null) {
        dispatchChange('ParcelForm', 'listPrice', '');
        dispatchChange('ParcelForm', 'salePrice', '');
      }
    }
  };

  const copySenderToConsignee = () => {
    dispatchChange('ParcelForm', 'consigneeCustomer', senderCustomerValue);
  };
  const copySenderToClient = () => {
    dispatchChange('ParcelForm', 'customer', senderCustomerValue);
  };

  const handleLocationChange = (location) => {
    setDestinationCityId(location.city ? location.city.id : 0);
  };

  const renderDeliveryFields = () =>
    !showDeliveryFields ? null : (
      <>
        <FormGroup row>
          <FormItem label="Distritos Autorizados" required>
            <Field
              name="district"
              component={Select}
              options={[{ value: 1, label: 'Lima' }]}
              validate={[isRequired]}
            />
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="Dirección" required>
            <Field
              name="address"
              component={TextInput}
              type="text"
              placeholder="Dirección"
              validate={[isRequired]}
            />
          </FormItem>
        </FormGroup>
      </>
    );

  const renderDynamicForm = () => {
    if (sourceCityId === 0 || destinationCityId === 0) {
      return <Alert message="Seleccione Origen y Destino" />;
    }
    if (loading) {
      return <Loader />;
    }
    return (
      <DynamicForm
        name="cargaItems"
        columns={generateCargoItemColumns(
          onQuantityInputChange,
          onCommonProductChange,
          onWeightChange,
        )}
        onClickRemove={onClickRemove}
      />
    );
  };

  const renderCalculatePriceButton = () => {
    if (
      sourceCityId === 0 ||
      destinationCityId === 0 ||
      cargaItemsValues.length === 0
    ) {
      return null;
    }
    return (
      <Button
        type="button"
        outline
        color="primary"
        onClick={() => calculateItemListPrice()}
      >
        Calcular precio
      </Button>
    );
  };

  const renderDetraction = () => {
    const detractionField = showDetraction ? (
      <>
        <FormGroup row>
          <FormItem label="Detracción">
            <Field
              name="detraction"
              component={TextInput}
              type="text"
              placeholder="Detracción"
              disabled
              validate={[validateNumber]}
            />
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="Precio Total de Venta con Detracción">
            <Field
              name="salePriceWithDetraction"
              component={TextInput}
              type="text"
              placeholder="Precio Total de Venta con Detracción"
              disabled
              validate={[validateNumber]}
            />
          </FormItem>
        </FormGroup>
      </>
    ) : null;
    return detractionField;
  };

  const renderClient = () => {
    if (
      selectedVoucherType &&
      selectedVoucherType.value === VOUCHER_TYPE_BUSINESS_INVOICE_ID
    ) {
      dispatchChange('ParcelForm', 'customer', '');
      return (
        <BusinessInputGroup
          label="Empresa"
          labelRequired
          name="business"
          form="ParcelForm"
          validate={[isRequired]}
          showDetails
        />
      );
    }
    dispatchChange('ParcelForm', 'business', '');
    return (
      <CustomerInputGroup
        label="Cliente"
        labelRequired
        name="customer"
        form="ParcelForm"
        validate={[isRequired]}
        showDetails
        disabled={isCopyClientChecked}
      />
    );
  };

  const handleClientSelectChange = (isChecked) => {
    if (isChecked) {
      copySenderToClient();
      setIsCopyClientChecked(true);
      return;
    }
    setIsCopyClientChecked(false);
    dispatchChange('ParcelForm', 'customer', '');
  };

  const handleDiscountCodeChange = (isChecked) => {
    if (isChecked) {
      setIsDiscountCodeChecked(true);
      return;
    }
    setIsDiscountCodeChecked(false);
    dispatchChange('ParcelForm', 'discountCode', '');
    dispatchChange('ParcelForm', 'discountPrice', '');
  };

  const renderCopySenderToButton = () => {
    if (!senderCustomerValue) {
      return null;
    }
    const copyButton = (action, className) =>
      action === copySenderToConsignee ? (
        <Button
          type="button"
          className={className}
          outline
          color="primary"
          onClick={action}
        >
          Copiar Consignado
        </Button>
      ) : (
        <FormItem>
          <Label>
            <Field
              name="client"
              id="client"
              component="input"
              type="checkbox"
              onChange={(e) => handleClientSelectChange(e.target.checked)}
            />{' '}
            Copiar Cliente
          </Label>
        </FormItem>
      );
    return selectedVoucherType &&
      selectedVoucherType.value === VOUCHER_TYPE_BUSINESS_INVOICE_ID ? (
      <FormGroup row>
        <FormItem>{copyButton(copySenderToConsignee)}</FormItem>
      </FormGroup>
    ) : (
      <>
        <FormGroup row>
          <FormItem>{copyButton(copySenderToConsignee)}</FormItem>
        </FormGroup>
        <FormGroup row>{copyButton(copySenderToClient)}</FormGroup>
      </>
    );
  };

  const onHandleSubmit = (formValues) => {
    if (validateItemValues(cargaItemsValues)) {
      onSubmit(formValues, isDiscountCodeChecked);
    }
  };

  const onShowVoucherCode = () => {
    dispatchChange('CargoPickupModelForm', 'voucherCode', '');
  };

  const renderSecondaryConsigneeFields = () =>
    !showSecondaryConsigneeField ? null : (
      <>
        <CustomerInputGroup
          label="Consignado Secundario"
          labelRequired
          name="secondaryConsigneeCustomer"
          form="ParcelForm"
          validate={[isRequired]}
          showDetails
        />
      </>
    );

  if (showLoader) {
    return <Loader />;
  }

  return (
    <>
      <Form onSubmit={handleSubmit(onHandleSubmit)}>
        <FormGroup row>
          <FormItem label="Tipo de Comprobante" required>
            <Field
              name="voucherType"
              component={VoucherTypeSelect}
              validate={[isRequired]}
            />
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="Origen" required>
            <Field
              name="sourceLocation"
              component={CargoLocationSelect}
              isDisabled
              validate={[isRequired]}
            />
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="Destino" required>
            <Field
              name="destinationLocation"
              component={CargoLocationSelect}
              onChange={(option) => handleLocationChange(option)}
              validate={[isRequired]}
              forPickupCargo
            />
          </FormItem>
        </FormGroup>
        <CustomerInputGroup
          label="Remitente"
          labelRequired
          name="senderCustomer"
          form="ParcelForm"
          validate={[isRequired]}
          showDetails
        />
        {renderCopySenderToButton()}
        <CustomerInputGroup
          label="Consignado"
          labelRequired
          name="consigneeCustomer"
          form="ParcelForm"
          validate={[isRequired]}
          showDetails
        />
        {renderClient()}
        <FormGroup row>
          <FormItem>
            <Label>
              <Field
                name="secondaryConsignee"
                id="secondaryConsignee"
                component="input"
                type="checkbox"
                onChange={(e) =>
                  SetShowSecondaryConsigneeField(e.target.checked)
                }
              />{' '}
              Agregar Consignado Secundario
            </Label>
          </FormItem>
        </FormGroup>
        {renderSecondaryConsigneeFields()}
        <PaymentMethodInputGroup onShowVoucherCode={onShowVoucherCode} />
        <FormGroup row>
          <FormItem>
            <Label>
              <Field
                name="delivery"
                id="delivery"
                component="input"
                type="checkbox"
                onChange={(e) => setShowDeliveryFields(e.target.checked)}
              />{' '}
              Entrega a Domicilio
            </Label>
          </FormItem>
        </FormGroup>
        {renderDeliveryFields()}
        <FormGroup row>
          <FormItem label="Clave Secreta" required>
            <Field
              className="password"
              name="secretCode"
              component={TextInput}
              type="text"
              placeholder="Clave Secreta"
              validate={[isRequired, validateNumber, validataMaxLength10]}
            />
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="Confirmar Clave Secreta" required>
            <Field
              className="password"
              name="confirmSecretCode"
              component={TextInput}
              type="text"
              placeholder="Confirmar Clave Secreta"
              validate={[
                isRequired,
                validateNumber,
                validataMaxLength10,
                validateSecretCode,
              ]}
            />
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="Documento Relacionado">
            <Field
              name="attachedDocument"
              component={TextInput}
              type="text"
              placeholder="Guia Remisión y/o Factura"
            />
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="Nota">
            <Field
              name="note"
              component={TextInput}
              type="textarea"
              placeholder="Nota"
            />
          </FormItem>
        </FormGroup>
        <h5>Detalle de Carga</h5>
        {renderDynamicForm()}
        {cargaItemsValues.length > 0 && (
          <>
            <FormGroup row>
              <FormItem>
                <Label>
                  <Field
                    name="discount"
                    id="discount"
                    component="input"
                    type="checkbox"
                    onChange={(e) => handleDiscountCodeChange(e.target.checked)}
                  />{' '}
                  Aplicar Descuento
                </Label>
              </FormItem>
            </FormGroup>
            <FormGroup row>
              <FormItem label="Código de Descuento">
                <Field
                  name="discountCode"
                  component={TextInput}
                  type="text"
                  placeholder="Código de Descuento"
                  disabled={!isDiscountCodeChecked}
                  validate={isDiscountCodeChecked ? [isRequired] : []}
                />
              </FormItem>
            </FormGroup>
          </>
        )}

        <FormGroup row>
          <FormItem label="Precio Total de Venta">
            <Field
              name="salePrice"
              component={TextInput}
              type="text"
              placeholder="Precio Total de Venta"
              disabled
              validate={
                isDiscountCodeChecked ? [isRequired, validateNumber] : []
              }
            />
          </FormItem>
        </FormGroup>
        {isDiscountCodeChecked && (
          <>
            <FormGroup row>
              <FormItem label="Precio Total con Descuento">
                <Field
                  name="discountPrice"
                  component={TextInput}
                  type="text"
                  placeholder="Precio Total con Descuento"
                  disabled
                  validate={[isRequired, validateNumber]}
                />
              </FormItem>
            </FormGroup>
          </>
        )}

        {renderDetraction()}
        <FormFooter>{renderCalculatePriceButton()}</FormFooter>
      </Form>
    </>
  );
};

ParcelForm.propTypes = {
  prices: PropTypes.instanceOf(Immutable.Map).isRequired,
  salePriceValue: PropTypes.string,
  cargaItemsValues: PropTypes.instanceOf(Array),
  selectedVoucherType: PropTypes.instanceOf(Object),
  senderCustomerValue: PropTypes.instanceOf(Object),
  onSubmit: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  dispatchChange: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  commonProducts: PropTypes.instanceOf(Array).isRequired,
  activeSalesSession: PropTypes.instanceOf(Immutable.Map).isRequired,
  gettingActiveSalesSession: PropTypes.bool.isRequired,
  dispatchPostCargoPrice: PropTypes.func.isRequired,
  dispatchClearCargoPrice: PropTypes.func.isRequired,
  dispatchClearCargoPrices: PropTypes.func.isRequired,
  dispatchGetActiveSalesSession: PropTypes.func.isRequired,
  dispatchGetDetractionAmountBySalePrice: PropTypes.func.isRequired,
  discountCode: PropTypes.string,
  dispatchPush: PropTypes.func.isRequired,
};

ParcelForm.defaultProps = {
  salePriceValue: null,
  cargaItemsValues: [],
  selectedVoucherType: null,
  senderCustomerValue: null,
  loading: false,
  discountCode: null,
};

const mapStateToProps = (state) => ({
  loading: !state.CargoUnit.Cargo.getIn(['current', 'activity']).isEmpty(),
  loadingCustomer: !state.UserUnit.Customer.getIn([
    'current',
    'activity',
  ]).isEmpty(),
  cargaItemsValues: selector(state, 'cargaItems'),
  salePriceValue: selector(state, 'salePrice'),
  selectedVoucherType: selector(state, 'voucherType'),
  senderCustomerValue: selector(state, 'senderCustomer'),
  consigneeCustomerValue: selector(state, 'consigneeCustomer'),
  prices: state.CargoUnit.Cargo.getIn(['current', 'price']),
  gettingActiveSalesSession: state.SalesUnit.SalesSession.getIn([
    'active',
    'loading',
  ]),
  activeSalesSession: state.SalesUnit.SalesSession.getIn(['active', 'content']),
  commonProducts: state.CargoUnit.CommonProduct.getIn([
    'all',
    'content',
    'content',
  ]),
  discountCode: selector(state, 'discountCode'),
});

const mapDispatchToProps = {
  dispatchChange: change,
  dispatchPostCargoPrice: postCargoPrice,
  dispatchClearCargoPrices: clearCargoPrices,
  dispatchClearCargoPrice: clearCargoPrice,
  dispatchGetActiveSalesSession: getActiveSalesSession,
  dispatchGetDetractionAmountBySalePrice: getDetractionAmountBySalePrice,
  dispatchPush: push,
};

const formComponent = reduxForm({
  form: 'ParcelForm',
  initialValues: {
    voucherType: DEFAULT_VOUCHER_TYPE_OPTION,
  },
})(ParcelForm);

export default connect(mapStateToProps, mapDispatchToProps)(formComponent);
