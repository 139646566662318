export const FLAG_GETTING_COMPANIES = 'FLAG_GETTING_COMPANIES';
export const GET_COMPANIES = 'GET_COMPANIES';
export const CLEAR_COMPANIES = 'CLEAR_COMPANIES';
export const FLAG_COMPANY_ACTIVITY = 'FLAG_COMPANY_ACTIVITY';
export const GET_COMPANY = 'GET_COMPANY';
export const CLEAR_COMPANY = 'CLEAR_COMPANY';
export const FLAG_GETTING_COMPANY_CUSTOMERS = 'FLAG_GETTING_COMPANY_CUSTOMERS';
export const GET_COMPANY_CUSTOMERS = 'GET_COMPANY_CUSTOMERS';
export const CLEAR_COMPANY_CUSTOMERS = 'CLEAR_COMPANY_CUSTOMERS';
export const FLAG_GETTING_COMPANY_BUSINESSES =
  'FLAG_GETTING_COMPANY_BUSINESSES';
export const GET_COMPANY_BUSINESSES = 'GET_COMPANY_BUSINESSES';
export const CLEAR_COMPANY_BUSINESSES = 'CLEAR_COMPANY_BUSINESSES';
