import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { ButtonToolbar } from 'reactstrap';
import {
  generateItineraryByProcessStatusEditPath,
  PROCESS_PATH,
} from '../../../../config/paths';
import ProcessDuplicateButton from './ProcessDuplicateButton';
import ProcessDeleteButton from './ProcessDeleteButton';

const ProcessToolbar = ({ processId }) => (
  <ButtonToolbar className="pull-right">
    <div>
      <ProcessDuplicateButton processId={processId} />{' '}
      <Link
        className="btn btn-warning"
        to={`${PROCESS_PATH}/${processId}/edit-schedule`}
      >
        <i className="fa fa-calendar-o" /> Editar horarios
      </Link>{' '}
      <Link
        className="btn btn-primary"
        to={`${PROCESS_PATH}/${processId}/edit`}
      >
        <i className="fa fa-pencil-square-o" /> Editar proceso
      </Link>{' '}
      <Link
        className="btn btn-info"
        to={generateItineraryByProcessStatusEditPath(processId)}
      >
        <i className="fa fa-pencil-square-o" /> Cambiar estados
      </Link>{' '}
      <ProcessDeleteButton processId={processId} />
    </div>
  </ButtonToolbar>
);

ProcessToolbar.propTypes = {
  processId: PropTypes.number.isRequired,
};

export default ProcessToolbar;
