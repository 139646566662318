import QueryString from 'query-string';
import { toastr } from 'react-redux-toastr';
import { push } from 'react-router-redux';
import {
  CLEAR_CARGO_PRICE,
  FLAG_PARCEL_ACTIVITY,
  POST_CARGO_PRICE,
  CLEAR_CARGO_PRICES,
  FLAG_GETTING_PARCELS,
  GET_PARCELS,
  CLEAR_PARCELS,
  GET_PARCEL,
  CLEAR_PARCEL,
  PRINT_CARGO_VOUCHER_ON_MOUNT,
} from '../types/cargo';
import {
  CARGO_PRICE_ENDPOINT,
  PARCEL_ENDPOINT,
  POST_PARCEL_COMMENT_ENDPOINT,
  generatePostParcelRefund,
  generatePostParcelChangeCode,
  generatePutParcelPay,
  generateSalesSessionParcelsEnpoint,
  generatePostCreditNoteParcel,
  generatePostSendParcelByEmailEndpoint,
} from '../../config/endpoints';
import {
  DEFAULT_POST_CONFIG,
  DEFAULT_GET_CONFIG,
  DEFAULT_PUT_CONFIG,
} from '../../config/rest';
import { isErrorResponse } from '../../utils/error-handlers';
import { CARGO_PATH } from '../../config/paths';

const clearCargoPrices = () => (dispatch) =>
  dispatch({
    type: CLEAR_CARGO_PRICES,
  });

const clearCargoPrice = (index) => (dispatch) =>
  dispatch({
    type: CLEAR_CARGO_PRICE,
    payload: index,
  });

const flagParcelActivity = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_PARCEL_ACTIVITY,
    payload: flag,
  });

const flagGettingParcels = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_GETTING_PARCELS,
    payload: flag,
  });

const clearParcels = () => (dispatch) =>
  dispatch({
    type: CLEAR_PARCELS,
  });

const getParcels = async (tableFilters) => async (dispatch) => {
  try {
    dispatch(flagGettingParcels(true));
    const query = tableFilters;
    const url = `${PARCEL_ENDPOINT}?${QueryString.stringify(query)}`;
    const response = await fetch(url, DEFAULT_GET_CONFIG);
    await isErrorResponse(response);
    const parcels = await response.json();
    dispatch({
      type: GET_PARCELS,
      payload: parcels,
    });
  } catch (error) {
    toastr.error('Error', error.message);
  } finally {
    dispatch(flagGettingParcels(false));
  }
};

const getSalesSessionParcels =
  async ({ salesSessionId }, tableFilters) =>
  async (dispatch) => {
    try {
      dispatch(flagGettingParcels(true));
      const query = {
        ...tableFilters,
        query: [...tableFilters.query, `salesSessionId:${salesSessionId}`],
      };
      const url = `${generateSalesSessionParcelsEnpoint(
        salesSessionId,
      )}?${QueryString.stringify(query)}`;
      const response = await fetch(url, { ...DEFAULT_GET_CONFIG });
      await isErrorResponse(response);
      const parcels = await response.json();
      dispatch({
        type: GET_PARCELS,
        payload: parcels,
      });
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagGettingParcels(false));
    }
  };

const postCargoPrice =
  async (
    indexes,
    { sourceCityId, destinationCityId, cargoItemList, discountCodeString },
  ) =>
  async (dispatch) => {
    try {
      dispatch(flagParcelActivity(true));
      const payload = {
        sourceCityId,
        destinationCityId,
        cargoItemList,
        discountCodeString,
      };
      const url = CARGO_PRICE_ENDPOINT;
      const response = await fetch(url, {
        ...DEFAULT_POST_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(response);
      const prices = await response.json();
      const payloadForDispatch = {
        pricesList: prices.cargoPricingList.map((price, index) => ({
          index: indexes[index],
          price,
        })),
        totalPrice: prices.totalPrice,
        finalPrice: prices.finalPrice,
      };
      dispatch({
        type: POST_CARGO_PRICE,
        payload: payloadForDispatch,
      });
      return prices;
    } catch (error) {
      toastr.error('Error', error.message);
      return null;
    } finally {
      dispatch(flagParcelActivity(false));
    }
  };

const flagPrintCargoVoucher = (flag) => (dispatch) =>
  dispatch({
    type: PRINT_CARGO_VOUCHER_ON_MOUNT,
    payload: flag,
  });

const postParcel =
  async ({
    voucherTypeId,
    voucherCode,
    sourceCityId,
    sourceLocationId,
    destinationCityId,
    destinationLocationId,
    senderCustomerId,
    consigneeCustomerId,
    secondaryConsigneeCustomerId,
    paymentMethodId,
    comments,
    cargoItemList,
    listPrice,
    salePrice,
    cargoDelivery,
    customerId,
    businessId,
    secretCode,
    attachedDocument,
    discountCodeString,
    salesPriceWithDiscount,
  }) =>
  async (dispatch) => {
    try {
      dispatch(flagParcelActivity(true));
      const payload = {
        voucherTypeId,
        voucherCode,
        sourceCityId,
        sourceLocationId,
        destinationCityId,
        destinationLocationId,
        senderCustomerId,
        consigneeCustomerId,
        secondaryConsigneeCustomerId,
        paymentMethodId,
        comments,
        cargoItemList,
        listPrice,
        salePrice,
        cargoDelivery,
        customerId,
        businessId,
        secretCode,
        attachedDocument,
        discountCodeString,
        salesPriceWithDiscount,
      };
      const url = PARCEL_ENDPOINT;
      const response = await fetch(url, {
        ...DEFAULT_POST_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(response);
      const parcel = await response.json();

      dispatch(push(`${CARGO_PATH}/${parcel.id}`));
      dispatch(flagPrintCargoVoucher(true));
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagParcelActivity(false));
    }
  };

const getParcel =
  async ({ parcelId }) =>
  async (dispatch) => {
    try {
      dispatch(flagParcelActivity(true));
      const url = `${PARCEL_ENDPOINT}/${parcelId}`;
      const response = await fetch(url, { ...DEFAULT_GET_CONFIG });
      await isErrorResponse(response);
      const parcel = await response.json();
      dispatch({
        type: GET_PARCEL,
        payload: parcel,
      });
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagParcelActivity(false));
    }
  };

const clearParcel = () => (dispatch) =>
  dispatch({
    type: CLEAR_PARCEL,
  });

const postParcelRefund =
  async ({ parcelId }) =>
  async (dispatch) => {
    try {
      dispatch(flagParcelActivity(true));
      const url = generatePostParcelRefund(parcelId);
      const response = await fetch(url, {
        ...DEFAULT_POST_CONFIG,
      });

      await isErrorResponse(response, () => getParcel({ parcelId }), dispatch);
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagParcelActivity(false));
    }
  };

const putParcelToPay =
  ({ parcelId, paymentMethodId, voucherCode }) =>
  async (dispatch) => {
    try {
      dispatch(flagGettingParcels(true));
      const url = generatePutParcelPay(parcelId);
      const payload = {
        paymentMethodId,
        voucherCode,
      };
      const response = await fetch(url, {
        ...DEFAULT_PUT_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(response);
      toastr.success('Entregado', 'Carga pagada correctamente');
      return true;
    } catch (error) {
      toastr.error('Error', error.message);
      return false;
    } finally {
      dispatch(flagGettingParcels(false));
    }
  };

const fetchParcel = async (parcelId, dispatch) => {
  const urlGet = `${PARCEL_ENDPOINT}/${parcelId}`;
  const responseGet = await fetch(urlGet, DEFAULT_GET_CONFIG);
  await isErrorResponse(responseGet);
  const item = await responseGet.json();
  dispatch({
    type: GET_PARCEL,
    payload: item,
  });
};

const postParcelComment =
  ({ parcelId, description }) =>
  async (dispatch) => {
    try {
      dispatch(flagParcelActivity(true));
      const payload = {
        parcelId,
        description,
      };
      const url = POST_PARCEL_COMMENT_ENDPOINT;
      const response = await fetch(url, {
        ...DEFAULT_POST_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(response);
      await fetchParcel(parcelId, dispatch);
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagParcelActivity(false));
    }
    return null;
  };

const postParcelChangeSecretCode =
  ({ parcelId, secretCode }) =>
  async (dispatch) => {
    try {
      dispatch(flagGettingParcels(true));
      const url = generatePostParcelChangeCode(parcelId);
      const response = await fetch(url, {
        ...DEFAULT_PUT_CONFIG,
        body: JSON.stringify({ secretCode }),
      });
      await isErrorResponse(
        response,
        () => {
          toastr.success('Exito', 'Clave Secreta cambiada correctamente');
          return getParcel({ parcelId });
        },
        dispatch,
        'put',
      );
      return true;
    } catch (error) {
      toastr.error('Error', error.message);
      return false;
    } finally {
      dispatch(flagGettingParcels(false));
    }
  };

const postCreditNoteParcel =
  ({ parcelId, creditNoteAmount }) =>
  async (dispatch) => {
    try {
      dispatch(flagParcelActivity(true));
      const url = generatePostCreditNoteParcel(parcelId);
      const response = await fetch(url, {
        ...DEFAULT_POST_CONFIG,
        body: JSON.stringify({ creditNoteAmount }),
      });
      await isErrorResponse(
        response,
        () => {
          toastr.success('Exito', 'Nota de Crédito Creada');
          return getParcel({ parcelId });
        },
        dispatch,
        'put',
      );
      return true;
    } catch (error) {
      toastr.error('Error', error.message);
      return false;
    } finally {
      dispatch(flagParcelActivity(false));
    }
  };

const postParcelSendEmail =
  ({ parcelId, email }) =>
  async () => {
    try {
      const url = generatePostSendParcelByEmailEndpoint(parcelId);
      const payload = [email];
      const response = await fetch(url, {
        ...DEFAULT_POST_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(response);
      toastr.success('Exito', 'Correo enviado correctamente');
    } catch (error) {
      toastr.error('Error', error.message);
    }
  };

export {
  clearCargoPrices,
  clearCargoPrice,
  postCargoPrice,
  postParcel,
  getParcels,
  getSalesSessionParcels,
  clearParcels,
  flagParcelActivity,
  getParcel,
  clearParcel,
  postParcelRefund,
  putParcelToPay,
  postParcelComment,
  postParcelChangeSecretCode,
  flagPrintCargoVoucher,
  postCreditNoteParcel,
  postParcelSendEmail,
};
