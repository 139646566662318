import React, { useEffect, useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../../common/resource/proptypes/CommonPropTypes';
import {
  COMPANY_STAFF_TYPE,
  DEFAULT_PAGE_SIZE,
  ITINERARY_TYPE,
  ITINERARY_TYPE_CONTRACT,
  TRIP_CIRCUIT_STATUS,
} from '../../../../../config/constants';
import {
  ITINERARY_CREATED_FROM_API_WARNING_MESSAGE,
  ITINERARY_SCHEDULE_WITHOUT_SEGMENT_LIST_MESSAGE,
  ITINERARY_WITHOUT_CABIN_CREW_INFO_MESSAGE,
  ITINERARY_WITHOUT_DRIVER_INFO_MESSAGE,
  ITINERARY_WITHOUT_PASSENGER_FLOW_MESSAGE,
  PENDING_SCHEDULING_WARNING_MESSAGE,
} from '../../../../../config/messages';
import {
  CUSTOMER_PATH,
  ITINERARY_PATH,
  ITINERARY_SCHEDULE_PATH,
  OLD_FRONT_CIRCUIT_PATH,
  OLD_FRONT_ROUTE_PATH,
  OLD_FRONT_SEAT_MAP_PATH,
  PRICING_PROFILE_PATH,
} from '../../../../../config/paths';
import {
  clearDrivers,
  clearItinerary,
  clearItinerarySchedule,
  clearPassengerFlowForItinerary,
  clearPersonnelForItinerary,
  getItinerary,
  getItinerarySchedule,
  getListDriversForItinerary,
  getPassengerFlowForItinerary,
  getPersonnelForItinerary,
  postVerifyCustomer,
  putBoardPassenger,
} from '../../../../../actions';
import { tzNormalizeDate } from '../../../../../utils/date';
import { generatePersonnelColumns } from '../../../../../config/columns';
import {
  DATE_TIME_FORMAT,
  FULL_DATE_TIME_FORMAT,
} from '../../../../../config/locale';
import { filterMethodCaseInsensitive } from '../../../../../utils/filter-methods';
import Modal from '../../../../common/modal/Modal';
import Table from '../../../../common/Table';
import Alert from '../../../../common/informative/Alert';
import Loader from '../../../../common/Loader';
import Content from '../../../../layout/Content';
import Checkbox from '../../../../common/forms/Checkbox';
import IsActive from '../../../../common/cells/IsActive';
import BadgeList from '../../../../common/BadgeList';
import ItineraryToolbar from './ItineraryToolbar';
import NoDataResource from '../../../../common/resource/NoDataResource';
import ResourceProperty from '../../../../common/resource/ResourceProperty';
import TimetableTemplate from '../TimetableTemplate';
import LinkToModuleResource from '../../../../common/resource/LinkToModuleResource';
import ItineraryPassengerFlowForm from './ItineraryPassengerFlowForm';

export const Itinerary = ({
  breadcrumbs,
  itinerary,
  loading,
  personnelLoading,
  itinerarySchedule,
  loadingItinerarySchedule,
  passengerFlow,
  drivers,
  isForMine,
  personnel,
  dispatchClearPersonnelForItinerary,
  dispatchGetPersonnelForItinerary,
  dispatchGetItinerarySchedule,
  dispatchClearItinerary,
  dispatchClearItinerarySchedule,
  dispatchClearPassengerFlowForItinerary,
  dispatchClearDrivers,
  dispatchGetItinerary,
  dispatchGetPassengerFlowForItinerary,
  dispatchGetListDriversForItinerary,
  dispatchPostVerifyCustomer,
  dispatchPutBoardPassenger,
  match: {
    params: { id: itineraryId },
  },
}) => {
  useLayoutEffect(() => {
    dispatchGetItinerary({ itineraryId });
    dispatchGetListDriversForItinerary({ itineraryId });
    dispatchGetPassengerFlowForItinerary({ itineraryId });
    if (isForMine) dispatchGetPersonnelForItinerary({ itineraryId });
    return () => {
      dispatchClearItinerary();
      dispatchClearItinerarySchedule();
      dispatchClearPassengerFlowForItinerary();
      dispatchClearDrivers();
      dispatchClearPersonnelForItinerary();
    };
  }, []);

  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalBody, setModalBody] = useState(null);

  useEffect(() => {
    if (itinerary.get('itineraryScheduleId') && itinerarySchedule.isEmpty()) {
      dispatchGetItinerarySchedule({
        itineraryScheduleId: itinerary.get('itineraryScheduleId'),
      });
    }
  }, [itinerary]);

  const onSubmitPassengerFlow = (formValues) => {
    dispatchPutBoardPassenger({ ...formValues, itineraryId });
  };

  const renderPassengerFlowModal = (type, currentPassengerFlow, index) => {
    const isBoarding = type === 'boarding';
    const title = `${isBoarding ? 'Embarque' : 'Desembarque'}: ${
      currentPassengerFlow.locationName
    } (${currentPassengerFlow[`${type}Passengers`]})`;

    const body = (
      <ItineraryPassengerFlowForm
        onSubmit={onSubmitPassengerFlow}
        type={type}
        index={index}
      />
    );

    setShowModal(true);
    setModalTitle(title);
    setModalBody(body);
  };

  const renderPassengersFlow = (type, currentPassengerFlow, index) => {
    let passengers = 0;

    if (currentPassengerFlow[`${type}Passengers`]) {
      passengers = (
        <div
          role="button"
          onClick={() =>
            renderPassengerFlowModal(type, currentPassengerFlow, index)
          }
          onKeyPress={() =>
            renderPassengerFlowModal(type, currentPassengerFlow, index)
          }
          tabIndex="0"
          className="clickable"
        >
          <strong>
            <u>{currentPassengerFlow[`${type}Passengers`]}</u>
          </strong>
        </div>
      );
    }
    return passengers;
  };

  const verifyCustomer = ({ type, id: customerId }) => {
    dispatchPostVerifyCustomer({ itineraryId, customerId, type });
  };

  let content;
  let toolbar;

  if (loading || loadingItinerarySchedule || itinerarySchedule.isEmpty()) {
    content = <Loader />;
  } else if (itinerary.isEmpty()) {
    content = <NoDataResource returnPage={ITINERARY_PATH} />;
  } else {
    const {
      createdFromApi,
      itineraryScheduleId,
      followsTemplate,
      isMaciva,
      firstFloorPriceVariation,
      secondFloorPriceVariation,
      type,
      company,
      itineraryCorrelationId,
      route,
      circuit,
      serviceType,
      active,
      seatMap,
      driverCommission,
      cabinCrewCommission,
      departureTime,
      trip,
      id,
      companyItineraryAditionalFields,
    } = itinerary.toJS();

    let createdFromApiMessageAlert = null;

    if (createdFromApi) {
      createdFromApiMessageAlert = (
        <Alert
          type="warning"
          message={ITINERARY_CREATED_FROM_API_WARNING_MESSAGE}
        />
      );
    }

    let templateAlert = null;

    if (itineraryScheduleId && !isForMine) {
      const link = (
        <Link
          to={`${ITINERARY_SCHEDULE_PATH}/${itinerary.get(
            'itineraryScheduleId',
          )}`}
        >
          esta plantilla
        </Link>
      );

      let infoMessageOfFollowsATemplate = (
        <span>Este itinerario esta asociado con {link}, pero no la sigue</span>
      );

      if (followsTemplate) {
        infoMessageOfFollowsATemplate = (
          <span>Este itinerario sigue {link}</span>
        );
      }

      templateAlert = (
        <Alert type="info" message={infoMessageOfFollowsATemplate} />
      );
    }

    const firstFloorPriceVariationContent =
      firstFloorPriceVariation !== 0 ? (
        <ResourceProperty label="Variación de Precio del Primer Piso:">
          {firstFloorPriceVariation || '-'}
        </ResourceProperty>
      ) : null;

    const secondFloorPriceVariationContent =
      secondFloorPriceVariation !== 0 ? (
        <ResourceProperty label="Variación de Precio del Segundo Piso:">
          {secondFloorPriceVariation || '-'}
        </ResourceProperty>
      ) : null;

    let companyResource = null;

    if (type === ITINERARY_TYPE_CONTRACT) {
      companyResource = (
        <ResourceProperty label="Compañia Contrato:">
          {company ? company.name : '-'}
        </ResourceProperty>
      );
    }

    let basicInformationResource = (
      <>
        <h3>Información Básica</h3>
        <ResourceProperty label="ID de Correlación Civa:">
          {itineraryCorrelationId || '-'}
        </ResourceProperty>
        <ResourceProperty label="Ruta:">
          <LinkToModuleResource
            text={`${route.id} - ${route.name}`}
            href={`${OLD_FRONT_ROUTE_PATH}/${route.id}`}
            isExternalPath
          />
        </ResourceProperty>
        <ResourceProperty label="Circuito:">
          <LinkToModuleResource
            text={`${circuit.id} - ${circuit.name}`}
            href={`${OLD_FRONT_CIRCUIT_PATH}/${circuit.id}`}
            isExternalPath
          />
        </ResourceProperty>
        <ResourceProperty label="Tipo de Servicio:">
          {serviceType ? serviceType.name : '-'}
        </ResourceProperty>
        <ResourceProperty label="Maciva:">
          <Checkbox checked={isMaciva} />
        </ResourceProperty>
        <ResourceProperty label="Estado:">
          <IsActive value={active} />
        </ResourceProperty>
        <ResourceProperty label="Mapa de Asientos:">
          {seatMap ? (
            <LinkToModuleResource
              text={`${seatMap.id} - ${seatMap.name}`}
              href={`${OLD_FRONT_SEAT_MAP_PATH}/${seatMap.id}`}
              isExternalPath
            />
          ) : (
            '-'
          )}
        </ResourceProperty>
        <ResourceProperty label="Perfil de Precio:">
          <BadgeList
            textArray={itinerary
              .get('itineraryPricingProfiles')
              .map(({ pricingProfile }) => ({
                text: `${pricingProfile.id} - ${pricingProfile.name}`,
                href: `${PRICING_PROFILE_PATH}/${pricingProfile.id}`,
              }))}
          />
        </ResourceProperty>
        {firstFloorPriceVariationContent}
        {secondFloorPriceVariationContent}
        <ResourceProperty label="Comisión del Conductor:">
          {driverCommission
            ? `${driverCommission.id} - ${driverCommission.name}`
            : '-'}
        </ResourceProperty>
        <ResourceProperty label="Comisión del Servicio a Bordo:">
          {cabinCrewCommission ? cabinCrewCommission.name : '-'}
        </ResourceProperty>
        <ResourceProperty label="Tipo:">
          {ITINERARY_TYPE[type.toLowerCase()].label}
        </ResourceProperty>
        {companyResource}
      </>
    );

    if (isForMine && companyItineraryAditionalFields != null) {
      basicInformationResource = (
        <>
          <h3>Información Básica</h3>
          <ResourceProperty label="Nombre:">
            {itinerarySchedule.get('name') || '-'}
          </ResourceProperty>
          <ResourceProperty label="Régimen:">
            {companyItineraryAditionalFields.shift || '-'}
          </ResourceProperty>
          <ResourceProperty label="Ruta:">
            <LinkToModuleResource text={`${route.id} - ${route.name}`} />
          </ResourceProperty>
          <ResourceProperty label="Tipo:">
            {companyItineraryAditionalFields.staffType
              ? COMPANY_STAFF_TYPE[companyItineraryAditionalFields.staffType]
                  .label
              : '-'}
          </ResourceProperty>
          <ResourceProperty label="Estado:">
            <IsActive value={active} />
          </ResourceProperty>
          <ResourceProperty label="Última verificación masiva:">
            {companyItineraryAditionalFields.lastMassVerificationDate
              ? tzNormalizeDate({
                  date: companyItineraryAditionalFields.lastMassVerificationDate,
                  format: DATE_TIME_FORMAT,
                })
              : '-'}
          </ResourceProperty>
        </>
      );
    }

    let timetableTemplate = (
      <Alert message={ITINERARY_SCHEDULE_WITHOUT_SEGMENT_LIST_MESSAGE} />
    );

    if (itinerarySchedule.get('itineraryScheduleSegmentList').length) {
      timetableTemplate = (
        <TimetableTemplate
          itineraryScheduleSegmentList={itinerarySchedule.get(
            'itineraryScheduleSegmentList',
          )}
          estimatedDepartureTime={departureTime}
        />
      );
    }

    const personnelList = personnel.toJS();

    const personnelTable = personnelLoading ? (
      <Loader />
    ) : (
      <Table
        columns={generatePersonnelColumns(verifyCustomer)}
        data={personnelList}
        defaultPageSize={
          personnelList.length <= 10 ? personnelList.length : DEFAULT_PAGE_SIZE
        }
        showPagination={personnelList.length > 10}
        manual={false}
        modelPath={CUSTOMER_PATH}
        filterable
        openPathInNewTab
        navigateToModelOnRowClick
        defaultFilterMethod={filterMethodCaseInsensitive}
      />
    );

    const personnelResource =
      isForMine && !personnel.isEmpty() ? (
        <>
          <h3>Personal</h3>
          <div className="mb-3">{personnelTable}</div>
        </>
      ) : null;

    const timetableResource = (
      <>
        <h3>Horarios</h3>
        <div className="mb-3">{timetableTemplate}</div>
      </>
    );

    let passengerFlowTable = (
      <Alert
        type="warning"
        message={ITINERARY_WITHOUT_PASSENGER_FLOW_MESSAGE}
      />
    );

    if (!passengerFlow.isEmpty()) {
      const passengerFlowData = passengerFlow.get(
        'passengerFlowForLocationList',
      );

      passengerFlowTable = (
        <div className="mb-3">
          <Table
            columns={[
              {
                Header: 'Parada Autorizada',
                accessor: 'locationName',
                className: 'text-center',
              },
              {
                Header: 'Embarques',
                accessor: 'boardingPassengers',
                className: 'text-center',
                Cell: (row) =>
                  renderPassengersFlow('boarding', row.original, row.index),
              },
              {
                Header: 'Desembarques',
                accessor: 'leavingPassengers',
                className: 'text-center',
                Cell: (row) =>
                  renderPassengersFlow('leaving', row.original, row.index),
              },
            ]}
            data={passengerFlowData}
            defaultPageSize={passengerFlowData.length}
            showPagination={false}
          />
        </div>
      );
    }

    const passengerFlowResource = (
      <>
        <h3>Flujo de Pasajeros</h3>
        {passengerFlowTable}
      </>
    );

    let scheduledItineraryMessage = (
      <div className="mt-3">
        <Alert type="warning" message={PENDING_SCHEDULING_WARNING_MESSAGE} />
      </div>
    );

    let scheduledItineraryResources = null;

    let scheduled = false;

    if (trip) {
      scheduled = true;

      const {
        assignedRegisteredBus,
        tripStatus,
        tripCircuitStatus,
        driverAssignmentList,
        cabinCrewAssignmentList,
        estimatedDepartureTime,
        estimatedArrivalTime,
        actualArrivalTime,
        actualDepartureTime,
        initialOdometerReading,
        finalOdometerReading,
      } = trip;

      const busResource = (
        <>
          <h3>Bus</h3>
          <ResourceProperty label="Número:">
            {assignedRegisteredBus ? assignedRegisteredBus.companyBusId : '-'}
          </ResourceProperty>
          <ResourceProperty label="Mapa de Asientos:">
            {assignedRegisteredBus ? assignedRegisteredBus.seatMap.name : '-'}
          </ResourceProperty>
          <ResourceProperty label="Matrícula:">
            {assignedRegisteredBus ? assignedRegisteredBus.licensePlate : '-'}
          </ResourceProperty>
          <ResourceProperty label="Estado:">{tripStatus.name}</ResourceProperty>
        </>
      );

      const circuitResource = (
        <>
          <h3>Circuito</h3>
          <ResourceProperty label="Orden dentro del Circuito:">
            {tripCircuitStatus
              ? TRIP_CIRCUIT_STATUS[tripCircuitStatus].label
              : '-'}
          </ResourceProperty>
        </>
      );

      const driverData = driverAssignmentList;

      let driverResource = (
        <>
          <Alert message={ITINERARY_WITHOUT_DRIVER_INFO_MESSAGE} />
        </>
      );

      if (driverData.length > 0) {
        driverResource = (
          <div className="mb-3">
            <Table
              columns={[
                {
                  Header: 'Nombre',
                  accessor: 'driver.customer.fullName',
                  className: 'text-center',
                },
                {
                  Header: 'DNI',
                  accessor: 'driver.customer.idDocumentNumber',
                  className: 'text-center',
                },
              ]}
              data={driverData}
              defaultPageSize={driverData.length}
              showPagination={false}
            />
          </div>
        );
      }

      const cabinCrewData = cabinCrewAssignmentList;

      let cabinCrewResource = (
        <>
          <Alert
            type="warning"
            message={ITINERARY_WITHOUT_CABIN_CREW_INFO_MESSAGE}
          />
        </>
      );

      if (cabinCrewData.length > 0) {
        cabinCrewResource = (
          <div className="mb-3">
            <Table
              columns={[
                {
                  Header: 'Nombre',
                  accessor: 'cabinCrew.customer.fullName',
                  className: 'text-center',
                },
                {
                  Header: 'DNI',
                  accessor: 'cabinCrew.customer.idDocumentNumber',
                  className: 'text-center',
                },
              ]}
              data={cabinCrewData}
              defaultPageSize={cabinCrewData.length}
              showPagination={false}
            />
          </div>
        );
      }

      const stadisticResource = (
        <>
          <h3>Estadísticas</h3>
          <ResourceProperty label="Hora de Salida Estimada:">
            {estimatedDepartureTime
              ? tzNormalizeDate({
                  date: estimatedDepartureTime,
                  format: FULL_DATE_TIME_FORMAT,
                })
              : '-'}
          </ResourceProperty>
          <ResourceProperty label="Hora de Salida Actual:">
            {actualDepartureTime
              ? tzNormalizeDate({
                  date: actualDepartureTime,
                  format: FULL_DATE_TIME_FORMAT,
                })
              : '-'}
          </ResourceProperty>
          <ResourceProperty label="Hora de Llegada Estimada:">
            {estimatedArrivalTime
              ? tzNormalizeDate({
                  date: estimatedArrivalTime,
                  format: FULL_DATE_TIME_FORMAT,
                })
              : '-'}
          </ResourceProperty>
          <ResourceProperty label="Hora de Llegada Actual:">
            {actualArrivalTime
              ? tzNormalizeDate({
                  date: actualArrivalTime,
                  format: FULL_DATE_TIME_FORMAT,
                })
              : '-'}
          </ResourceProperty>
          <ResourceProperty label="Lectura de Odómetro Inicial:">
            {initialOdometerReading || '-'}
          </ResourceProperty>
          <ResourceProperty label="Lectura de Odómetro Final:">
            {finalOdometerReading || '-'}
          </ResourceProperty>
        </>
      );

      scheduledItineraryMessage = null;

      scheduledItineraryResources = (
        <>
          {busResource}
          {circuitResource}
          {!isForMine ? (
            <>
              <h3>Conductores</h3>

              {driverResource}
            </>
          ) : null}
          {!isForMine ? (
            <>
              <h3>Servicio a Bordo</h3>
              {cabinCrewResource}
            </>
          ) : null}
          {!isForMine ? stadisticResource : null}
        </>
      );
    }

    content = (
      <>
        {createdFromApiMessageAlert}
        {templateAlert}
        {scheduledItineraryMessage}
        {basicInformationResource}
        {timetableResource}
        {passengerFlowResource}
        {scheduledItineraryResources}
        {personnelResource}
        <Modal
          show={showModal}
          title={modalTitle}
          body={modalBody}
          onClickClose={() => setShowModal(false)}
        />
      </>
    );

    toolbar = (
      <ItineraryToolbar
        itineraryId={id}
        drivers={drivers}
        scheduled={scheduled}
        type={type}
        itinerary={itinerary}
        isForMine={isForMine > 0}
      />
    );
  }

  return (
    <Content
      breadcrumbs={breadcrumbs}
      toolbar={toolbar}
      title="Itinerario"
      content={content}
    />
  );
};

const mapStateToProps = ({ ItineraryUnit, authentication }) => ({
  breadcrumbs: [
    ...ItineraryUnit.UnitHome.get('breadcrumbs'),
    { text: 'Itinerarios', href: ITINERARY_PATH },
    { text: 'Ver', href: '' },
  ],
  itinerary: ItineraryUnit.Itinerary.getIn(['current', 'content']),
  loading: !ItineraryUnit.Itinerary.getIn(['current', 'activity']).isEmpty(),
  personnelLoading: !ItineraryUnit.Itinerary.getIn([
    'current',
    'personnelActivity',
  ]).isEmpty(),
  itinerarySchedule: ItineraryUnit.ItinerarySchedule.getIn([
    'current',
    'content',
  ]),
  loadingItinerarySchedule: !ItineraryUnit.ItinerarySchedule.getIn([
    'current',
    'activity',
  ]).isEmpty(),
  passengerFlow: ItineraryUnit.Itinerary.getIn(['current', 'passengerFlow']),
  personnel: ItineraryUnit.Itinerary.getIn(['current', 'personnel']),
  drivers: ItineraryUnit.Itinerary.getIn(['current', 'drivers']),
  isForMine: !!authentication.get('user').salesSessionUserId,
});

const mapDispatchToProps = {
  dispatchGetItinerary: getItinerary,
  dispatchClearItinerary: clearItinerary,
  dispatchGetItinerarySchedule: getItinerarySchedule,
  dispatchClearItinerarySchedule: clearItinerarySchedule,
  dispatchGetPassengerFlowForItinerary: getPassengerFlowForItinerary,
  dispatchClearPassengerFlowForItinerary: clearPassengerFlowForItinerary,
  dispatchGetListDriversForItinerary: getListDriversForItinerary,
  dispatchClearDrivers: clearDrivers,
  dispatchPutBoardPassenger: putBoardPassenger,
  dispatchClearPersonnelForItinerary: clearPersonnelForItinerary,
  dispatchGetPersonnelForItinerary: getPersonnelForItinerary,
  dispatchPostVerifyCustomer: postVerifyCustomer,
};

Itinerary.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  match: matchPropTypes.isRequired,
  itinerary: PropTypes.instanceOf(Immutable.Map).isRequired,
  loading: PropTypes.bool.isRequired,
  personnelLoading: PropTypes.bool.isRequired,
  dispatchGetItinerary: PropTypes.func.isRequired,
  dispatchClearItinerary: PropTypes.func.isRequired,
  itinerarySchedule: PropTypes.instanceOf(Immutable.Map).isRequired,
  loadingItinerarySchedule: PropTypes.bool.isRequired,
  dispatchGetItinerarySchedule: PropTypes.func.isRequired,
  dispatchClearItinerarySchedule: PropTypes.func.isRequired,
  passengerFlow: PropTypes.instanceOf(Immutable.Map).isRequired,
  personnel: PropTypes.instanceOf(Immutable.Set).isRequired,
  dispatchGetPassengerFlowForItinerary: PropTypes.func.isRequired,
  dispatchClearPassengerFlowForItinerary: PropTypes.func.isRequired,
  dispatchGetListDriversForItinerary: PropTypes.func.isRequired,
  dispatchPutBoardPassenger: PropTypes.func.isRequired,
  dispatchClearDrivers: PropTypes.func.isRequired,
  dispatchClearPersonnelForItinerary: PropTypes.func.isRequired,
  dispatchGetPersonnelForItinerary: PropTypes.func.isRequired,
  dispatchPostVerifyCustomer: PropTypes.func.isRequired,
  drivers: PropTypes.instanceOf(Immutable.Set).isRequired,
  isForMine: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Itinerary);
