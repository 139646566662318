import React, { useLayoutEffect } from 'react';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { Button, ButtonToolbar, Form } from 'reactstrap';
import SelectionableTable from '../../../../common/forms/table/SelectionableTable';
import { MANIFEST_CARGO_ITEM_TO_ITINERARY_COLUMNS } from '../../../../../config/columns';
import {
  CARGO_OPERATIONS,
  DEFAULT_PAGE_SIZE,
} from '../../../../../config/constants';
import Loader from '../../../../common/Loader';
import { NO_MANIFEST_CARGO_ITEM_IN_ITINERARY } from '../../../../../config/messages';
import {
  clearManifestItems,
  deleteManifestItemFromItinerary,
  putRemoveItemFromItineraryV2,
} from '../../../../../actions';
import Alert from '../../../../common/informative/Alert';
import CargoOperationByQrButton from '../cargo-operation-qr/CargoOperationByQrButton';

const UnloadCargoFromItinerary = ({
  items,
  loading,
  itineraryId,
  handleSubmit,
  registeredBusId,
  destinationLocationId,
  dispatchClearManifestItem,
  dispatchPutRemoveItemFromItineraryV2,
  dispatchDeleteManifestItemFromItinerary,
  disabledButton,
}) => {
  useLayoutEffect(
    () => () => {
      dispatchClearManifestItem();
    },
    [],
  );

  const onClickUnloadCargoFromItinerary = async ({
    itemsToUnloadCorrelations,
  }) => {
    if (!itemsToUnloadCorrelations || !itemsToUnloadCorrelations.length) {
      return;
    }
    const updatedItems = items.map((item) => ({
      ...item,
      arrived: itemsToUnloadCorrelations.includes(item.correlation),
    }));

    const removed = await dispatchPutRemoveItemFromItineraryV2({
      cargoItemList: updatedItems,
      itineraryId,
      registeredBusId,
      destinationLocationId,
    });
    if (removed && removed.length) {
      dispatchDeleteManifestItemFromItinerary(removed);
    }
  };

  const toolbar = (
    <ButtonToolbar className="pull-right">
      <div>
        <Button type="submit" color="success" disabled={disabledButton}>
          Bajar
        </Button>{' '}
        <CargoOperationByQrButton
          onClickAddItemToItinerary={onClickUnloadCargoFromItinerary}
          cargoOperation={CARGO_OPERATIONS.UNLOAD_ITEM}
        />
      </div>
    </ButtonToolbar>
  );
  const data = items.toJS();
  const defaultPageSize =
    items.size > DEFAULT_PAGE_SIZE ? DEFAULT_PAGE_SIZE : items.size;

  if (items.size === 0) {
    if (loading) {
      return <Loader />;
    }
    return (
      <Alert type="warning" message={NO_MANIFEST_CARGO_ITEM_IN_ITINERARY} />
    );
  }

  return (
    <Form onSubmit={handleSubmit(onClickUnloadCargoFromItinerary)}>
      <div className="mt-3">
        {toolbar}
        <div className="flex">
          <h4>Selecione las encomiendas a bajar</h4>
        </div>
        <SelectionableTable
          columns={MANIFEST_CARGO_ITEM_TO_ITINERARY_COLUMNS}
          data={data}
          name="itemsToUnloadCorrelations"
          defaultPageSize={defaultPageSize}
          returnOnlySelectedItems
          keyField="correlation"
          loading={loading}
          form="UnloadCargoFromItineraryForm"
        />
      </div>
    </Form>
  );
};

UnloadCargoFromItinerary.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  items: PropTypes.instanceOf(Immutable.List).isRequired,
  loading: PropTypes.bool.isRequired,
  itineraryId: PropTypes.number,
  registeredBusId: PropTypes.number,
  destinationLocationId: PropTypes.number.isRequired,
  dispatchClearManifestItem: PropTypes.func.isRequired,
  dispatchPutRemoveItemFromItineraryV2: PropTypes.func.isRequired,
  dispatchDeleteManifestItemFromItinerary: PropTypes.func.isRequired,
  disabledButton: PropTypes.bool.isRequired,
};

UnloadCargoFromItinerary.defaultProps = {
  itineraryId: null,
  registeredBusId: null,
};

const mapStateToProps = ({ CargoUnit }) => ({
  items: CargoUnit.ManifestCargo.getIn(['all', 'content']),
  loading: CargoUnit.ManifestCargo.getIn(['all', 'loading']),
  disabledButton: CargoUnit.ManifestCargo.getIn(['disabledActions']),
});

const mapDispatchToProps = {
  dispatchPutRemoveItemFromItineraryV2: putRemoveItemFromItineraryV2,
  dispatchClearManifestItem: clearManifestItems,
  dispatchDeleteManifestItemFromItinerary: deleteManifestItemFromItinerary,
};

const formComponent = reduxForm({
  form: 'UnloadCargoFromItineraryForm',
})(UnloadCargoFromItinerary);

export default connect(mapStateToProps, mapDispatchToProps)(formComponent);
