import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Container, Col, Row } from 'reactstrap';
import Breadcrumbs from '../../../layout/Breadcrumbs';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import ParcelForm from './ParcelForm';
import { postParcel } from '../../../../actions';
import { CARGO_PATH } from '../../../../config/paths';

export class NewParcel extends Component {
  onSubmit = (formValues, isDiscountCodeChecked) => {
    const totalListPrice = formValues.cargaItems.reduce(
      (sum, item) => sum + parseFloat(item.listPrice),
      0,
    );
    const totalSalePrice = formValues.cargaItems.reduce(
      (sum, item) => sum + parseFloat(item.salePrice),
      0,
    );
    const {
      voucherType,
      sourceLocation,
      destinationLocation,
      senderCustomer,
      consigneeCustomer,
      paymentMethod,
      note,
      cargaItems,
      customer,
      business,
    } = formValues;

    const newFormValues = {
      ...formValues,
      voucherTypeId: voucherType.value,
      sourceCityId: sourceLocation.city.id,
      sourceLocationId: sourceLocation.value,
      destinationCityId: destinationLocation.city.id,
      destinationLocationId: destinationLocation.value,
      senderCustomerId: senderCustomer.value,
      consigneeCustomerId: consigneeCustomer.value,
      paymentMethodId: paymentMethod.value,
      comments: note,
      cargoItemList: cargaItems.map((item) => ({
        cargoCategoryId: item.cargoCategoryId
          ? item.cargoCategoryId.value
          : null,
        commonProductId: item.commonProductId
          ? item.commonProductId.value
          : null,
        description: item.description,
        listPrice: item.listPrice,
        salePrice: item.salePrice,
        quantity: item.quantity,
        weight: item.weight,
        perishable: !!item.perishable,
      })),
      listPrice: totalListPrice,
      salePrice: totalSalePrice,
      customerId: customer ? customer.value : null,
      businessId: business ? business.value : null,
      discountCodeString: isDiscountCodeChecked
        ? formValues.discountCode
        : null,
      salesPriceWithDiscount: isDiscountCodeChecked
        ? parseFloat(formValues.discountPrice)
        : null,
    };
    if (formValues.delivery) {
      newFormValues.cargoDelivery = {
        districtId: formValues.district.value,
        address: formValues.address,
      };
    }
    if (formValues.secondaryConsignee) {
      newFormValues.secondaryConsigneeCustomerId =
        formValues.secondaryConsigneeCustomer.value;
    }

    this.props.dispatchPostParcel(newFormValues);
  };

  render() {
    const { breadcrumbs } = this.props;
    return (
      <Container fluid>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <Row>
          <Col>
            <h1>Recepción de Carga</h1>
            <p>Crear nueva Recepción de Carga.</p>
          </Col>
        </Row>
        <ParcelForm onSubmit={this.onSubmit} />
      </Container>
    );
  }
}

const mapStateToProps = ({ CargoUnit }) => ({
  breadcrumbs: [
    ...CargoUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Carga',
      href: CARGO_PATH,
    },
    {
      text: 'Recepción de Carga',
      href: '',
    },
  ],
});

const mapDispatchToProps = { dispatchPostParcel: postParcel };

NewParcel.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchPostParcel: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(NewParcel);
