import React from 'react';
import PropTypes from 'prop-types';
import { ButtonGroup, ButtonToolbar } from 'reactstrap';
import { Link } from 'react-router-dom';

// TODO: add support for multiple buttons in toolbar
const TableToolbar = ({
  buttonPath,
  buttonText,
  disabled,
  isExternalPath,
  buttonClassName,
  icon,
  extraButtons,
}) => {
  const iconElement = icon ? <i className={`${icon}`} /> : null;

  const renderButton = () => {
    if (extraButtons) {
      return extraButtons;
    }

    if (isExternalPath) {
      return (
        <a disabled={disabled} className="btn btn-primary" href={buttonPath}>
          {iconElement} {buttonText}
        </a>
      );
    }

    return (
      <Link className={buttonClassName} to={buttonPath} disabled={disabled}>
        {iconElement} {buttonText}
      </Link>
    );
  };

  return (
    <ButtonToolbar className="pull-right">
      <ButtonGroup>{renderButton()}</ButtonGroup>
    </ButtonToolbar>
  );
};

TableToolbar.propTypes = {
  buttonPath: PropTypes.string.isRequired,
  buttonText: PropTypes.string,
  buttonClassName: PropTypes.string,
  disabled: PropTypes.bool,
  isExternalPath: PropTypes.bool,
  icon: PropTypes.string,
  extraButtons: PropTypes.instanceOf(Object),
};

TableToolbar.defaultProps = {
  buttonText: 'Crear',
  disabled: false,
  isExternalPath: false,
  icon: 'fa fa-plus-circle',
  buttonClassName: 'btn btn-primary',
  extraButtons: null,
};

export default TableToolbar;
