import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { postParcelRefund } from '../../../../actions';
import { PARCEL_STATUS } from '../../../../config/constants';
import ConfirmationModal from '../../../common/modal/ConfirmationModal';

class RefundButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showConfirmationModal: false,
    };
  }

  showConfirmationModal = () => {
    this.setState({ showConfirmationModal: true });
  };

  closeConfirmationModal = () => {
    this.setState({ showConfirmationModal: false });
  };

  handleParcelRefund = () => {
    const { dispatchPostParcelRefund, parcelId } = this.props;

    dispatchPostParcelRefund({ parcelId });
  };

  render() {
    if (this.props.status !== PARCEL_STATUS.RECEIVED.value) {
      return null;
    }

    return (
      <Fragment>
        <Button color="primary" onClick={this.showConfirmationModal}>
          Devolución
        </Button>
        <ConfirmationModal
          show={this.state.showConfirmationModal}
          onClickConfirm={this.handleParcelRefund}
          onClickCancel={this.closeConfirmationModal}
        />
      </Fragment>
    );
  }
}
RefundButton.propTypes = {
  dispatchPostParcelRefund: PropTypes.func.isRequired,
  parcelId: PropTypes.number.isRequired,
  status: PropTypes.string.isRequired,
};

const mapDispatchToProps = {
  dispatchPostParcelRefund: postParcelRefund,
};

export default connect(null, mapDispatchToProps)(RefundButton);
