import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import withEndpointAuthorization from '../../../units/authorization/withEndPointAuthorization';
import { POST } from '../../../../config/permissions';
import { TICKET_TO_OTHER_CUSTOMER_ENDPOINT } from '../../../../config/endpoints';

export const ReserveToOtherCustomerButton = ({ onClick, allSeatsSelected }) => (
  <Button
    className="mt-3"
    disabled={!allSeatsSelected}
    color="primary"
    onClick={onClick}
    size="lg"
  >
    Reservar para otra persona <i className="fa fa-arrow-circle-right" />
  </Button>
);

ReserveToOtherCustomerButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  allSeatsSelected: PropTypes.bool.isRequired,
};

export default withEndpointAuthorization({
  url: TICKET_TO_OTHER_CUSTOMER_ENDPOINT,
  permissionType: POST,
})(ReserveToOtherCustomerButton);
