import React, { useLayoutEffect } from 'react';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { PROCESS_PATH } from '../../../../config/paths';
import { clearProcess, getProcess, putProcess } from '../../../../actions';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import NoDataResource from '../../../common/resource/NoDataResource';
import Loader from '../../../common/Loader';
import Content from '../../../layout/Content';
import EditProcessForm from './EditProcessForm';
import { COMPANY_STAFF_TYPE } from '../../../../config/constants';

export const EditProcess = ({
  breadcrumbs,
  dispatchClearProcess,
  dispatchGetProcess,
  dispatchPutProcess,
  loading,
  match: {
    params: { id: processId },
  },
  process,
}) => {
  useLayoutEffect(() => {
    dispatchGetProcess({ processId });

    return () => dispatchClearProcess();
  }, []);

  const onSubmit = (formValues) => dispatchPutProcess(processId, formValues);

  let content;

  if (loading) content = <Loader />;
  else if (process.isEmpty())
    content = <NoDataResource returnPage={PROCESS_PATH} />;
  else {
    const processObj = process.toJS();

    const shifts = processObj.shift.split(',').map((shift) => ({
      value: shift,
      label: shift,
    }));

    const staffType = {
      value: processObj.staffType,
      label: COMPANY_STAFF_TYPE[processObj.staffType].label,
    };

    const initialValues = {
      name: processObj.name,
      shift: shifts,
      staffType,
      registrationDate: processObj.registrationDate,
      closedDate: processObj.closedDate,
    };

    content = (
      <EditProcessForm
        initialValues={initialValues}
        onSubmit={onSubmit}
        routes={processObj.itineraryList
          .map((itinerary) => ({
            routeId: itinerary.routeId,
            routeName: itinerary.routeName,
          }))
          .filter(
            (value, index, self) =>
              index === self.findIndex((t) => t.routeId === value.routeId),
          )}
        startDate={processObj.departureTime}
      />
    );
  }

  return (
    <Content
      breadcrumbs={breadcrumbs}
      title="Editar proceso"
      subtitle="Editar proceso"
      content={content}
    />
  );
};

EditProcess.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchClearProcess: PropTypes.func.isRequired,
  dispatchGetProcess: PropTypes.func.isRequired,
  dispatchPutProcess: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  match: matchPropTypes.isRequired,
  process: PropTypes.instanceOf(Immutable.Map).isRequired,
};

EditProcess.defaultProps = {
  loading: false,
};

const mapStateToProps = (
  { ItineraryUnit },
  {
    match: {
      params: { id },
    },
  },
) => ({
  breadcrumbs: [
    ...ItineraryUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Lista de Procesos',
      href: PROCESS_PATH,
    },
    {
      text: 'Ver',
      href: `${PROCESS_PATH}/${id}`,
    },
    {
      text: 'Editar',
      href: '',
    },
  ],
  process: ItineraryUnit.Process.getIn(['current', 'content']),
  loading: !ItineraryUnit.Process.getIn(['current', 'activity']).isEmpty(),
});

const mapDispatchToProps = {
  dispatchClearProcess: clearProcess,
  dispatchGetProcess: getProcess,
  dispatchPutProcess: putProcess,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditProcess);
