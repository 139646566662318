import QueryString from 'query-string';
import { push } from 'react-router-redux';
import { toastr } from 'react-redux-toastr';
import {
  FLAG_GETTING_LOCATIONS,
  GET_LOCATIONS,
  CLEAR_LOCATIONS,
  CLEAR_LOCATION,
  FLAG_LOCATION_ACTIVITY,
  GET_LOCATION,
} from '../types';
import { LOCATION_ENDPOINT } from '../../config/endpoints';
import { isErrorResponse } from '../../utils/error-handlers';
import {
  DEFAULT_DELETE_CONFIG,
  DEFAULT_GET_CONFIG,
  DEFAULT_POST_CONFIG,
  DEFAULT_PUT_CONFIG,
} from '../../config/rest';
import { LOCATION_PATH } from '../../config/paths';

const flagGettingLocations = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_GETTING_LOCATIONS,
    payload: flag,
  });

const clearLocations = () => (dispatch) =>
  dispatch({
    type: CLEAR_LOCATIONS,
  });

const flagLocationActivity = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_LOCATION_ACTIVITY,
    payload: flag,
  });

const clearLocation = () => (dispatch) =>
  dispatch({
    type: CLEAR_LOCATION,
  });

const getLocations = async (tableFilters) => async (dispatch) => {
  try {
    dispatch(flagGettingLocations(true));
    const query = tableFilters;
    const url = `${LOCATION_ENDPOINT}?${QueryString.stringify(query)}`;
    const response = await fetch(url, { ...DEFAULT_GET_CONFIG });
    await isErrorResponse(response);
    const locations = await response.json();
    dispatch({
      type: GET_LOCATIONS,
      payload: locations,
    });
  } catch ({ message }) {
    toastr.error('Error', message);
  } finally {
    dispatch(flagGettingLocations(false));
  }
};

const postLocation =
  async ({
    name,
    description,
    isHomeBase,
    addressId,
    address,
    locationCorrelationId,
    isTerminal,
    isPointOfSale,
    isStop,
    isToll,
    receiveCargoPoint,
    pickupCargoPoint,
    timezone,
    agencyCode,
    ubigeo,
    latitude,
    longitude,
  }) =>
  async (dispatch) => {
    dispatch(flagLocationActivity(true));
    try {
      const payload = {
        name,
        description,
        isHomeBase,
        addressId,
        address,
        locationCorrelationId,
        isTerminal,
        isPointOfSale,
        isStop,
        isToll,
        receiveCargoPoint,
        pickupCargoPoint,
        timezone,
        agencyCode,
        ubigeo,
        latitude,
        longitude,
        lackingData: false,
      };
      const url = LOCATION_ENDPOINT;
      const response = await fetch(url, {
        ...DEFAULT_POST_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(response);

      const location = await response.json();
      dispatch(push(`${LOCATION_PATH}/${location.id}`));
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagLocationActivity(false));
    }
  };

const getLocation =
  async ({ locationId }) =>
  async (dispatch) => {
    try {
      dispatch(flagLocationActivity(true));
      const url = `${LOCATION_ENDPOINT}/${locationId}`;
      const response = await fetch(url, DEFAULT_GET_CONFIG);
      await isErrorResponse(response);
      const location = await response.json();
      dispatch({
        type: GET_LOCATION,
        payload: location,
      });
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagLocationActivity(false));
    }
  };

const putLocation =
  async (
    locationId,
    {
      name,
      description,
      isHomeBase,
      addressId,
      address,
      locationCorrelationId,
      isTerminal,
      isPointOfSale,
      isStop,
      isToll,
      receiveCargoPoint,
      pickupCargoPoint,
      agencyCode,
      lackingData = false,
      timezone,
      ubigeo,
      latitude,
      longitude,
    },
  ) =>
  async (dispatch) => {
    try {
      dispatch(flagLocationActivity(true));

      const payload = {
        name,
        description,
        isHomeBase,
        addressId,
        address,
        locationCorrelationId,
        isTerminal,
        isPointOfSale,
        isStop,
        isToll,
        receiveCargoPoint,
        pickupCargoPoint,
        agencyCode,
        timezone,
        lackingData,
        id: locationId,
        ubigeo,
        latitude,
        longitude,
      };

      const url = `${LOCATION_ENDPOINT}/${locationId}`;
      const response = await fetch(url, {
        ...DEFAULT_PUT_CONFIG,
        body: JSON.stringify(payload),
      });

      await isErrorResponse(response);
      await response.json();
      dispatch(push(LOCATION_PATH));
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagLocationActivity(false));
    }
  };

const deleteLocation =
  async ({ id }) =>
  async (dispatch) => {
    try {
      dispatch(flagLocationActivity(true));
      const url = `${LOCATION_ENDPOINT}/${id}`;
      const response = await fetch(url, {
        ...DEFAULT_DELETE_CONFIG,
      });
      await isErrorResponse(response);
      await response.json();
      dispatch(push(LOCATION_PATH));
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagLocationActivity(false));
    }
  };

export {
  flagGettingLocations,
  getLocations,
  clearLocations,
  postLocation,
  clearLocation,
  deleteLocation,
  getLocation,
  putLocation,
};
