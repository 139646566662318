export const FLAG_GETTING_PARCELS = 'FLAG_GETTING_PARCELS';
export const GET_PARCELS = 'GET_PARCELS';
export const CLEAR_PARCELS = 'CLEAR_PARCELS';
export const FLAG_PARCEL_ACTIVITY = 'FLAG_PARCEL_ACTIVITY';
export const GET_PARCEL = 'GET_PARCEL';
export const CLEAR_PARCEL = 'CLEAR_PARCEL';
export const POST_CARGO_PRICE = 'POST_CARGO_PRICE';
export const CLEAR_CARGO_PRICE = 'CLEAR_CARGO_PRICE';
export const CLEAR_CARGO_PRICES = 'CLEAR_CARGO_PRICES';
export const PRINT_CARGO_VOUCHER_ON_MOUNT = 'PRINT_CARGO_VOUCHER_ON_MOUNT';
