import {
  DATE_TIME_FORMAT,
  INT_DATE_FORMAT,
  INT_DATE_TIME_FORMAT,
  TIME_FORMAT,
} from '../config/locale';
import { timeFormat, tzNormalizeDate } from './date';

const generateTimetableData = (routeSegmentList, departureTime, startDate) => {
  const timetableData = [];

  const formattedDepartureTime = departureTime
    ? tzNormalizeDate({ date: departureTime, format: TIME_FORMAT })
    : null;
  const formattedStartDate = startDate
    ? tzNormalizeDate({ date: startDate, format: INT_DATE_FORMAT })
    : null;

  let lastArrivalTimeWithoutFormat = null;
  let lastDurationAdjusment = 0;
  let lastLayover = 0;

  if (routeSegmentList) {
    routeSegmentList.forEach(
      (
        {
          segment: {
            id: segmentId,
            sourceLocation,
            destinationLocation,
            duration,
          },
        },
        index,
        array,
      ) => {
        const timetable = {
          segmentId,
          sourceLocationName: sourceLocation.name,
          destinationLocationName: destinationLocation.name,
          duration: timeFormat(duration),
          startOffset: '-',
          departureTime: '-',
          arrivalTime: '-',
        };

        if (startDate && departureTime) {
          if (index === 0) {
            const date = formattedStartDate;

            const time = formattedDepartureTime;

            timetable.startOffset = 0;

            timetable.departureTimeWithoutFormat = `${date} ${time}`;

            timetable.departureTime = tzNormalizeDate({
              date: timetable.departureTimeWithoutFormat,
            });

            timetable.departureTimeFormatted = tzNormalizeDate({
              date: timetable.departureTimeWithoutFormat,
              format: DATE_TIME_FORMAT,
            });
          } else {
            const { segment } = array[index - 1];

            const { startOffset: lastStartOffset } = timetableData[index - 1];

            timetable.startOffset =
              segment.duration +
              lastDurationAdjusment +
              lastLayover +
              lastStartOffset;

            timetable.departureTimeWithoutFormat = lastArrivalTimeWithoutFormat;

            timetable.departureTime = tzNormalizeDate({
              date: timetable.departureTimeWithoutFormat,
              addTime: { amount: lastLayover, unit: 'minutes' },
            });

            timetable.departureTimeFormatted = tzNormalizeDate({
              date: timetable.departureTimeWithoutFormat,
              addTime: { amount: lastLayover, unit: 'minutes' },
              format: DATE_TIME_FORMAT,
            });
          }

          lastDurationAdjusment = 0;

          timetable.segmentAdjustment = lastDurationAdjusment;

          timetable.arrivalTimeWithoutFormat = tzNormalizeDate({
            date: timetable.departureTimeWithoutFormat,
            addTime: {
              amount: duration + timetable.segmentAdjustment + lastLayover,
              unit: 'minutes',
            },
            format: INT_DATE_TIME_FORMAT,
          });

          timetable.arrivalTime = tzNormalizeDate({
            date: timetable.arrivalTimeWithoutFormat,
          });

          lastArrivalTimeWithoutFormat = timetable.arrivalTimeWithoutFormat;

          timetable.arrivalTimeFormatted = tzNormalizeDate({
            date: timetable.departureTimeWithoutFormat,
            addTime: {
              amount: duration + timetable.segmentAdjustment + lastLayover,
              unit: 'minutes',
            },
            format: DATE_TIME_FORMAT,
          });

          lastLayover = 0;

          timetable.layover = lastLayover;
        }

        timetableData.push(timetable);
      },
    );
  }
  return timetableData;
};

export default generateTimetableData;
